import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { ResponseCustom } from '../types';
import { cleanMenusImages } from './requests';

type UseCleanMenusImages = () => {
  cleanImages: UseMutateAsyncFunction<
    ResponseCustom<string> | void,
    unknown,
    void
  >;
  loading: boolean;
};

export const useCleanMenusImages: UseCleanMenusImages = () => {
  const token = localStorage.getItem('token') || '';

  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<string> | void,
    unknown,
    void
  >({
    mutationFn: () => cleanMenusImages(token),
    onSuccess: (response) => {
      if (response?.error) {
        alert(response.error);
      }
    },
  });

  return {
    cleanImages: mutateAsync,
    loading: isPending,
  };
};
