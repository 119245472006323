import { MealtimeProps } from '../../../common/types/menu';

export const mealtimes: MealtimeProps[] = [
  {
    time: 'breakfast',
    order: 1,
  },
  {
    time: 'snack-one',
    order: 2,
  },
  {
    time: 'lunch',
    order: 3,
  },
  {
    time: 'snack-two',
    order: 4,
  },
  {
    time: 'dinner',
    order: 5,
  },
  {
    time: 'snack-three',
    order: 6,
  },
];
