import cn from 'classnames';
import styles from '../../../common/pages/PrivacyPolicy/styles.module.scss';

export const textEn = (
  <>
    <h2 className={cn(styles.textCenter, styles.h1)}>eRates currency converter</h2>
    <h3 className={cn(styles.textCenter, styles.h2)}>Lifetime subscription agreement</h3>
    <p className={styles.mt1}>Last Updated Mar 16, 2024</p>
    <p className={styles.mt1}>
      Elvira Ospanova operates the website and the "eRates" mobile application (hereinafter referred
      to as the "Service"). This policy applies to all information that is collected or submitted
      through the "eRates" mobile application.
    </p>
    <p className={styles.mt1}>
      By purchasing a paid service, the user agrees to the following terms and conditions:
    </p>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        the "lifetime subscription" service is perpetual (has no expiration date) and is paid for
        only once.
      </p>
    </div>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        paid subscription services that are automatically renewed after expiration are regulated by
        the providers (suppliers) charging for such services (App Store, Google play). The user can
        disable auto-renewal of paid subscription in the personal cabinet of his/her account, which
        (account) is used to log in to the respective application store (App Store, Google play).
      </p>
    </div>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        by purchasing this service the user gets the right to receive all paid features of the
        application, including disabling advertising in the application, and also gets access to all
        paid features of the application, which are not yet published, but will appear later.
      </p>
    </div>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        The eRates app reserves the right to suspend any services to users at any time, including
        paid services previously purchased by users with a valid service term. This action may occur
        in the event of economic losses incurred by the owners of the application or caused by
        reasons beyond the control of the owner of the application, such as: the actions of third
        parties involved in the provision of services to the user of the application (Apple Store,
        Google play, API and other third-party services), preventing the provision of the service
        (termination of operations, technical failures, etc.), force majeure circumstances.
      </p>
    </div>
  </>
);
