import { useState } from 'react';
import { RecipeCategories } from '../../components/RecipeCategories';
import { RecipesList } from '../../components/RecipesList';
import { DEFAULT_RECIPE_CATEGORY } from '../../common/constants/common';
import { RecipeCategoriesEnum } from '../../common/types/recipes';

export const RecipesPage = () => {
  const currentCategory =
    (localStorage.getItem('currentRecipeCategory') as RecipeCategoriesEnum) ||
    DEFAULT_RECIPE_CATEGORY;
  const [category, setCategory] =
    useState<RecipeCategoriesEnum>(currentCategory);

  return (
    <div className="admin-page-wrapper">
      <RecipeCategories
        activeCategory={category}
        setActiveCategory={setCategory}
      />
      <RecipesList category={category} />
    </div>
  );
};
