import styles from './styles.module.scss';
import { FC, useMemo, useState } from 'react';
import { compareSnippetWithTextArray } from '../../components/UsersElasticList/utils/misc';
import cn from 'classnames';
import { usePatchEntitlement } from '../../requests/users/usePatchEntitlement';
import { useDeleteEntitlement } from '../../requests/users/useDeleteEntitlement';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Loader } from '../../../common/shared/Loader';

interface Props {
  documentId: string;
  menuIds?: (string | null)[];
  acquiredMenus?: { raw: string[]; snippet?: string };
  refetch?: () => void;
}

export const Entitlements: FC<Props> = ({
  documentId,
  menuIds,
  acquiredMenus,
  refetch,
}) => {
  const [entitlementId, setEntitlementId] = useState<string>('');

  const [showSetModal, setShowSetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleSetModal = () => {
    setShowSetModal((prev) => !prev);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const { patchEntitlement, loading: loadingPatch } = usePatchEntitlement();
  const { deleteEntitlement, loading: loadingDelete } = useDeleteEntitlement();

  const acquiredMenuIds = useMemo(() => {
    if (acquiredMenus) {
      return compareSnippetWithTextArray({
        text: acquiredMenus.raw,
        snippet: acquiredMenus.snippet,
      });
    }
  }, [acquiredMenus]);

  const acquiredMenuIdsObject = acquiredMenuIds?.reduce(
    (acc, id) => ({
      ...acc,
      [id]: id,
    }),
    {} as Record<string, string>
  );

  const onSetEntitlement = async () => {
    if (entitlementId) {
      await patchEntitlement({ documentId, entitlementId });
      refetch?.();
      toggleSetModal();
    }
  };

  const onDeleteEntitlement = async () => {
    if (entitlementId) {
      await deleteEntitlement({ documentId, entitlementId });
      refetch?.();
      toggleDeleteModal();
    }
  };

  const onClickEntitlement = (menuId: string | null, isAcquired: boolean) => {
    if (menuId) {
      setEntitlementId(menuId);
    }

    if (isAcquired) {
      toggleDeleteModal();
    } else {
      toggleSetModal();
    }
  };

  const SetText = (
    <>
      <span className={cn('bold', 'green-font')}>Назначить меню</span>
      <span className={cn('bold')}>{entitlementId}</span>
    </>
  );

  const DeleteText = (
    <>
      <span className={cn('bold', 'alert-font')}>Удалить меню</span>
      <span className={cn('bold')}>{entitlementId}</span>
    </>
  );

  return (
    <>
      <Loader visible={loadingPatch || loadingDelete} />
      <dl className={styles.row}>
        <dt>Меню</dt>
        <dd>
          {!!menuIds?.length &&
            menuIds.map((menuId) => {
              const isAcquired = !!acquiredMenuIdsObject?.[`${menuId}`];
              return (
                <button
                  key={menuId}
                  className={cn(styles.itemWidget, {
                    [styles.success]: isAcquired,
                  })}
                  onClick={() => onClickEntitlement(menuId, isAcquired)}
                >
                  <span>{menuId}</span>
                  <span>|</span>
                  <span>{isAcquired ? '➖' : '➕'}</span>
                </button>
              );
            })}
        </dd>
      </dl>
      {showSetModal && (
        <ConfirmModal
          onCloseModal={toggleSetModal}
          confirmHandler={onSetEntitlement}
          cancelHandler={toggleSetModal}
          customText={SetText}
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          onCloseModal={toggleDeleteModal}
          confirmHandler={onDeleteEntitlement}
          cancelHandler={toggleDeleteModal}
          customText={DeleteText}
        />
      )}
    </>
  );
};
