import { NutrientsProps } from './products';

export enum ProductType {
  product = 'product',
  recipe = 'recipe',
}

export enum AccessTypes {
  paid = 'paid',
  free = 'free',
}

export interface MenuDayProps {
  dayId: string;
  value: number;
  isPaid: boolean;
}

export type MealtimeTypes =
  | 'breakfast'
  | 'lunch'
  | 'dinner'
  | 'snack-one'
  | 'snack-two'
  | 'snack-three';

export interface MealtimeProps {
  time: MealtimeTypes;
  order: number;
}

export interface DisplayProduct {
  _id: string;
  order: number;
  internalId: string;
  productType: ProductType;
  name: string;
  weight: number;
  proteins: number;
  fat: number;
  carbohydrates: number;
  kcal: number;
  calculatedNutrients: NutrientsProps;
  mealtimes?: MealtimeProps[];
  productDescription?: string;
}

export interface MenuSet {
  day: MenuDayProps;
  products: DisplayProduct[] | null;
}

export interface FilteredMenus {
  day: MenuDayProps;
  products: DisplayProduct[];
}

export type CalcRecipeNutrients = ({
  internalId,
  order,
  recipeId,
  weight,
}: {
  internalId: string;
  order: number;
  recipeId: string;
  weight: number;
}) => Promise<DisplayProduct | void>;

export interface TotalNutrientsProps extends NutrientsProps {
  weight: number;
}

export interface MenuProductProps {
  order: number;
  internalId: string;
  productId: string;
  productType: ProductType;
  weight: number;
  mealtimes?: MealtimeProps[];
  productDescription?: string;
}

export interface MenuItemProps {
  day: MenuDayProps;
  products: MenuProductProps[];
}

export interface MenuSetProps {
  order: number;
  publicAccess: boolean;
  subscriptionGift: boolean;
  accessType: AccessTypes;
  purchaseId: string | null;
  imageName: string;
  name: string;
  shortName: string;
  description: string;
  kaspiPrice: number | null;
  menu: MenuItemProps[];
  weightProfit?: string;
  attachedMenuId?: string;
}

export interface MenusProps extends MenuSetProps {
  _id: string;
}
