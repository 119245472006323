import React, { ChangeEvent, FC, ReactNode } from 'react';
import { Spinner } from '../../../common/shared/SpinnerPretty';
import styles from './styles.module.scss';

interface Props {
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  loading?: boolean;
}

export const SearchInput: FC<Props> = ({
  onInputChange,
  children,
  loading = false,
}) => {
  return (
    <div className={styles.searchBlock}>
      <div className={styles.inputHolder}>
        <input type="text" onChange={onInputChange} />
        {loading && (
          <div className={styles.inputSpinner}>
            <Spinner color="grayColor" size="small" />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
