import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { ResponseCustom } from '../types';
import { UploadImageRequest } from './types';
import { uploadImage } from './requests';

type UseUploadImage = () => {
  uploadImage: UseMutateAsyncFunction<
    ResponseCustom<string> | void,
    unknown,
    Omit<UploadImageRequest, 'token'>
  >;
  loadingUploadImage: boolean;
  reset: () => void;
  data?: ResponseCustom<string>;
};

export const useUploadImage: UseUploadImage = () => {
  const token = localStorage.getItem('token') || '';

  const { mutateAsync, isPending, data, reset } = useMutation<
    ResponseCustom<string>,
    unknown,
    Omit<UploadImageRequest, 'token'>
  >({
    mutationFn: ({ formData, imageType }) =>
      uploadImage({ formData, imageType, token }),
    onSuccess: (response) => {
      if (response?.error) {
        alert(response.error);
      }
    },
  });

  return {
    uploadImage: mutateAsync,
    loadingUploadImage: isPending,
    reset,
    data,
  };
};
