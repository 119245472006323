import { useQuery } from '@tanstack/react-query';
import { LONG_STALE_TIME, QUERY_KEYS } from '../constants';
import { fetchUsersCountries } from './requests';
import { UsersCountriesResponse } from './types';

type UseFetchUsersCountries = () => {
  loading: boolean;
  countries?: UsersCountriesResponse[];
};

export const useFetchUsersCountries: UseFetchUsersCountries = () => {
  const token = localStorage.getItem('token') || '';

  const { data: response, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS_COUNTRIES, token],
    queryFn: () => fetchUsersCountries(token),
    refetchOnWindowFocus: false,
    staleTime: LONG_STALE_TIME,
  });

  return {
    loading: isLoading,
    countries: response?.data,
  };
};
