import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { fetchUserByCustomerId } from './requests';
import { ResponseCustom } from '../types';
import { FetchUserByCustomerIdRequest } from './types';
import { UserProps } from '../../common/types/users';

type UseFetchUserByCustomerId = () => {
  fetchUserByCustomerId: UseMutateFunction<
    ResponseCustom<UserProps | null>,
    unknown,
    FetchUserByCustomerIdRequest
  >;
  loading: boolean;
  user?: UserProps | null;
};

export const useFetchUserByCustomerId: UseFetchUserByCustomerId = () => {
  const {
    mutate,
    isPending,
    data: response,
  } = useMutation<
    ResponseCustom<UserProps | null>,
    unknown,
    FetchUserByCustomerIdRequest
  >({
    mutationFn: ({ customerId }) => fetchUserByCustomerId({ customerId }),
  });

  return {
    fetchUserByCustomerId: mutate,
    loading: isPending,
    user: response?.data,
  };
};
