import { useRouteError } from 'react-router-dom';
import cn from 'classnames';
import styles from './styles.module.scss';

export const ErrorPage = () => {
  const { statusText, message } = (useRouteError() as { statusText: string; message: string }) || {
    statusText: '',
    message: '',
  };

  return (
    <section className={cn('section', styles.section)}>
      <div className="container">
        <div className={styles.textWrapper}>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred</p>
          <h2>
            <i>{statusText || message}</i>
          </h2>
        </div>
      </div>
    </section>
  );
};
