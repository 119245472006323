import { FC, Fragment, ReactNode } from 'react';
import { Modal } from '../../../common/shared/Modal';
import styles from './styles.module.scss';

interface Props {
  leftButton: ReactNode;
  rightButtons: ReactNode[];
  showModal: boolean;
  closeModal: () => void;
  modalChildren: ReactNode;
}

export const ControlPanel: FC<Props> = ({
  leftButton,
  rightButtons,
  modalChildren,
  showModal,
  closeModal,
}) => {
  return (
    <div className={styles.wrapper}>
      {leftButton}
      <div className={styles.rightSide}>
        {rightButtons.map((button, index) => (
          <Fragment key={index}>{button}</Fragment>
        ))}
      </div>
      {showModal && <Modal onClose={closeModal}>{modalChildren}</Modal>}
    </div>
  );
};
