import { NutrientsProps } from '../types/products';
import { DEFAULT_PRODUCT_WEIGHT } from '../constants/products';

type CalcNutrients = ({
  proteins,
  fat,
  carbohydrates,
  kcal,
  weight,
}: {
  proteins: number;
  fat: number;
  carbohydrates: number;
  kcal: number;
  weight: number;
}) => NutrientsProps;

export const calcNutrients: CalcNutrients = ({
  proteins,
  fat,
  carbohydrates,
  kcal,
  weight,
}) => {
  return {
    proteins: (proteins / DEFAULT_PRODUCT_WEIGHT) * weight,
    fat: (fat / DEFAULT_PRODUCT_WEIGHT) * weight,
    carbohydrates: (carbohydrates / DEFAULT_PRODUCT_WEIGHT) * weight,
    kcal: (kcal / DEFAULT_PRODUCT_WEIGHT) * weight,
  };
};
