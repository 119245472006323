import { FC, useState } from 'react';
import { useFetchRecipesByCategory } from '../../requests/recipes/useFetchRecipesByCategory';
import { Loader } from '../../../common/shared/Loader';
import { Item } from './Item';
import { Button } from '../../../common/shared/Button';
import { Modal } from '../../../common/shared/Modal';
import { CreateRecipe } from '../CreateRecipe';
import { ControlPanel } from '../ControlPanel';
import { useCleanRecipesImages } from '../../requests/recipes/useCleanRecipesImages';
import { useChangeOrder, useSort } from './useChangeOrder';
import { RecipeCategoriesEnum, RecipeProps } from '../../common/types/recipes';
import styles from './styles.module.scss';

interface RecipesListProps {
  category: RecipeCategoriesEnum;
}

export const RecipesList: FC<RecipesListProps> = ({ category }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [notification, setNotification] = useState<string>('');

  const [dataSorted, setDataSorted] = useState<RecipeProps[]>([]);

  const {
    data,
    loading: fetchRecipeLoading,
    refetch,
  } = useFetchRecipesByCategory(category);
  const { cleanImages, loading: cleanImagesLoading } = useCleanRecipesImages();

  const { handleSortOrder, loading: patchRecipeKeyLoading } = useChangeOrder({
    dataSorted,
    category,
    refetch,
  });

  const { loading: sortLoading } = useSort({
    data,
    category,
    setDataSorted,
    refetch,
  });

  const onCleanImagesHandler = async () => {
    const response = await cleanImages();
    if (response?.data) {
      setNotification(response.data);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Loader
        visible={
          sortLoading ||
          fetchRecipeLoading ||
          patchRecipeKeyLoading ||
          cleanImagesLoading
        }
      />
      <ul className={styles.listWrapper}>
        {dataSorted.map((recipe) => (
          <div key={recipe._id} className={styles.itemWrapper}>
            <select
              className={styles.selectInput}
              value={recipe.sortOrder[category] || undefined}
              onChange={(e) => {
                handleSortOrder({
                  id: recipe._id,
                  orderNumber: Number(e.target.value),
                });
              }}
            >
              {dataSorted.map((item, index) => (
                <option key={item._id}>{index + 1}</option>
              ))}
            </select>
            <Item recipe={recipe} refetchRecipes={refetch} />
          </div>
        ))}
      </ul>
      <ControlPanel
        leftButton={
          <Button handler={() => setShowCreateModal(true)} blue>
            <span>Create recipe</span>
          </Button>
        }
        rightButtons={[
          <Button key="1" handler={onCleanImagesHandler} outlined>
            <span>Clean images</span>
          </Button>,
        ]}
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(false)}
        modalChildren={
          <CreateRecipe
            closeHandler={() => setShowCreateModal(false)}
            refetchRecipes={refetch}
          />
        }
      />
      {notification && (
        <Modal onClose={() => setNotification('')}>
          <p>{notification}</p>
        </Modal>
      )}
    </div>
  );
};
