import { clearEmTags, clearWhiteSpace } from '../../../common/utils/string';
import { getTimestampFromDate, reverseDate } from '../../../common/utils/date';

export const getValue = (filedName: { raw: string; snippet: string }) => {
  return filedName.snippet || filedName.raw;
};

type CompareSnippetWithTextArray = ({
  text,
  snippet,
}: {
  text: string | string[];
  snippet?: string;
}) => string[];

export const compareSnippetWithTextArray: CompareSnippetWithTextArray = ({
  text,
  snippet,
}) => {
  const textArray = Array.isArray(text) ? text : [text];
  if (snippet?.includes('<em>')) {
    const clearedSnippet = clearEmTags(snippet);
    const clearedSnippetString = clearWhiteSpace(clearedSnippet);
    return textArray.map((text) => {
      const clearedText = clearWhiteSpace(text);
      if (clearedText === clearedSnippetString) {
        return snippet;
      } else {
        return clearedText;
      }
    });
  } else {
    return textArray;
  }
};

export const getTimestampFromSubscriptionDate = (date: string) => {
  const reversedDate = reverseDate(date);
  return getTimestampFromDate(reversedDate);
};
