import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { searchProducts } from './request';
import { SearchProductsResults } from './types';
import { ResponseCustom } from '../types';
import { SearchRequest, SearchResponse } from '../../common/types/search';

type UseSearchProducts = () => {
  searchProducts: UseMutateFunction<
    ResponseCustom<SearchResponse<SearchProductsResults>>,
    unknown,
    SearchRequest
  >;
  loading: boolean;
  data?: SearchResponse<SearchProductsResults>;
};

export const useSearchProducts: UseSearchProducts = () => {
  const {
    mutate,
    data: response,
    isPending,
  } = useMutation<
    ResponseCustom<SearchResponse<SearchProductsResults>>,
    unknown,
    SearchRequest
  >({
    mutationFn: ({ query, page }) => searchProducts({ query, page }),
  });

  return {
    searchProducts: mutate,
    data: response?.data,
    loading: isPending,
  };
};
