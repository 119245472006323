import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useUploadImage } from '../../requests/app/useUploadImage';
import { Loader } from '../../../common/shared/Loader';
import { InputButton } from '../../shared/InputButton';
import { ImageType } from '../../requests/app/types';
import { ReplaceRecipeImageName } from '../../requests/recipes/useReplaceRecipeImageName';
import styles from './styles.module.scss';

interface UploadImageProps {
  imageType: ImageType;
  setImageName: (name: string) => void;
  itemId?: string;
  replaceImageName?: ReplaceRecipeImageName;
  refetch?: () => Promise<any>;
}

export const UploadImage: FC<UploadImageProps> = ({
  imageType,
  setImageName,
  itemId,
  replaceImageName,
  refetch,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { uploadImage, data: uploadResult, reset } = useUploadImage();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target?.files?.[0] || null);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      const uploadResult = await uploadImage({
        formData,
        imageType,
      });

      const newImageName = uploadResult?.data;

      if (itemId && newImageName && replaceImageName && refetch) {
        await replaceImageName({
          itemId,
          name: newImageName,
        });
        await refetch();
        setImageName(newImageName);
      }

      if (newImageName) {
        setImageName(newImageName);
      }
      setLoading(false);
    }
  };

  const removeImage = () => {
    reset();
    setImageName('');
    setSelectedFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <div className={styles.wrapper}>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <div className={styles.buttonsWrapper}>
          <InputButton
            className={styles.uploadButton}
            text="Upload image"
            handler={handleUpload}
          />
          {!!uploadResult?.data && (
            <InputButton
              className={styles.uploadButton}
              text="Remove image"
              handler={removeImage}
              alert
            />
          )}
        </div>
      </div>
    </>
  );
};
