import { useMemo } from 'react';
import { calcTotalDayNutrients } from '../utils';
import { MenuSet, TotalNutrientsProps } from '../../../common/types/menu';

type UseTotalMenu = ({
  menuSet,
}: {
  menuSet: MenuSet[];
}) => TotalNutrientsProps;

export const useTotalMenu: UseTotalMenu = ({ menuSet }) => {
  return useMemo(() => {
    return menuSet.reduce(
      (acc, item) => {
        const totalDay = calcTotalDayNutrients(item?.products || []);
        return {
          proteins: acc.proteins + totalDay.proteins,
          fat: acc.fat + totalDay.fat,
          carbohydrates: acc.carbohydrates + totalDay.carbohydrates,
          kcal: acc.kcal + totalDay.kcal,
          weight: acc.weight + totalDay.weight,
        };
      },
      {
        proteins: 0,
        fat: 0,
        carbohydrates: 0,
        kcal: 0,
        weight: 0,
      } as TotalNutrientsProps
    );
  }, [menuSet]);
};
