import React, { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import cn from 'classnames';
import { InputButton } from '../../../shared/InputButton';
import { Loader } from '../../../../common/shared/Loader';
import { Button } from '../../../../common/shared/Button';
import { CrossButton } from '../../../../common/shared/CrossButton';
import { DeleteButton } from '../../../shared/DeleteButton';
import { NutrientsCell } from './NutrientsCell';
import { Total } from './Total';
import { useTotalMenu } from '../hooks/useTotalMenu';
import { calcNutrients } from '../../../common/utils/calcNutrients';
import { calcTotalDayNutrients, findProduct } from '../utils';
import {
  AccessTypes,
  DisplayProduct,
  MealtimeProps,
  MenuSet,
  MenusProps,
} from '../../../common/types/menu';
import { ResponseCustom } from '../../../requests/types';
import modalStyles from './modal.module.scss';
import commonStyles from './styles.module.scss';
import { UploadImage } from '../../UploadImage';
import {
  IMAGE_ENDPOINT,
  MENU_IMG_PATH,
} from '../../../common/constants/common';
import { ImageType } from '../../../requests/app/types';
import { MenusOriginData } from '../../../pages/Menu';
import { mealtimes } from '../model/mealtimes';
import { CheckBox } from '../../../shared/CheckBox';
import { nanoid } from '../../../../common/utils/idGenerator';
import { RadioGroup } from '../../../shared/RadioGroup';
import { useSort } from '../hooks/useSort';
import { InputOrder } from '../../../shared/InputOrder';
import { useSaveMenu } from '../hooks/useSaveMenu';
import { ADD_PRODUCT_ID, ADD_RECIPE_ID } from '../constants';
import { useAddProduct } from '../hooks/useAddProduct';
import { useAddRecipe } from '../hooks/useAddRecipe';
import { useDeleteProduct } from '../hooks/useDeleteProduct';

interface Props {
  onClose: () => void;
  refetchMenus: () => Promise<
    QueryObserverResult<ResponseCustom<MenusProps[]>>
  >;
  menusLength: number;
  menusOriginData?: MenusOriginData[];
  initialMenu?: MenuSet[];
  initialOrder?: number;
  initialPublic?: boolean;
  initialAccessType?: AccessTypes;
  initialKaspiPrice?: number | null;
  initialSubscriptionGift?: boolean;
  initialImageName?: string;
  initialName?: string;
  initialShortName?: string;
  initialWeightProfit?: string;
  initialAttachedMenuId?: string;
  initialDescription?: string;
  menuId?: string;
  originPurchaseId?: string | null;
}

const initialMenuSet: MenuSet[] = [
  { day: { dayId: nanoid(), value: 1, isPaid: false }, products: [] },
];

export const EditMenu: FC<Props> = ({
  onClose,
  refetchMenus,
  menusLength,
  menusOriginData,
  initialMenu = initialMenuSet,
  initialOrder,
  initialPublic = false,
  initialAccessType = AccessTypes.paid,
  initialKaspiPrice = null,
  initialSubscriptionGift = false,
  initialImageName = '',
  initialName = '',
  initialShortName = '',
  initialDescription = '',
  initialWeightProfit = '',
  initialAttachedMenuId = '',
  menuId,
  originPurchaseId = null,
}) => {
  const menuOrder = useRef<number>(initialOrder || menusLength + 1);
  const menuName = useRef<string>(initialName);
  const menuShortName = useRef<string>(initialShortName);
  const weightProfit = useRef<string>(initialWeightProfit);
  const attachedMenuId = useRef<string>(initialAttachedMenuId);
  const menuDescription = useRef<string>(initialDescription);
  const purchaseId = useRef<string | null>(originPurchaseId);
  const kaspiPrice = useRef<number | null>(initialKaspiPrice);

  const [purchaseIdEdit, setPurchaseIdEdit] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(initialPublic);
  const [accessType, setAccessType] = useState<AccessTypes>(initialAccessType);
  const [subscriptionGift, setSubscriptionGift] = useState<boolean>(
    initialSubscriptionGift
  );

  const [imageName, setImageName] = useState<string>(initialImageName);
  const [menuSet, setMenuSet] = useState<MenuSet[]>(initialMenu);

  const { onChangeInputDay, onSortDays, onChangeInputProduct, onSortProducts } =
    useSort({
      menuSet,
      setMenuSet,
    });

  const isValidMenu = useMemo(() => {
    let isValid = true;
    menuSet.forEach(({ day, products }) => {
      if (day === null || products === null) {
        isValid = false;
      }
    });
    return isValid;
  }, [menuSet]);

  const { saveMenu, loading: saveLoading } = useSaveMenu({
    onClose,
    refetchMenus,
    menuOrder,
    accessType,
    menuName,
    menuShortName,
    weightProfit,
    attachedMenuId,
    menuSet,
    isPublic,
    subscriptionGift,
    imageName,
    menuDescription,
    purchaseId,
    kaspiPrice,
    originPurchaseId,
    menuId,
    menusOriginData,
    isValidMenu,
  });

  const editPurchaseIdHandler = () => {
    setPurchaseIdEdit((prev) => !prev);
  };

  const onChangeMenuOrder = (event: ChangeEvent<HTMLInputElement>) => {
    const rawOrder = parseFloat(event.target.value.trim());
    menuOrder.current = isFinite(rawOrder) ? rawOrder : menusLength + 1;
  };

  const onChangePurchaseId = (event: ChangeEvent<HTMLInputElement>) => {
    purchaseId.current = event.target.value.trim();
  };

  const onChangeMenuGift = () => {
    setSubscriptionGift((prev) => !prev);
  };

  const onChangeMenuName = (event: ChangeEvent<HTMLInputElement>) => {
    menuName.current = event.target.value.trim();
  };

  const onChangeMenuShortName = (event: ChangeEvent<HTMLInputElement>) => {
    menuShortName.current = event.target.value.trim();
  };

  const onChangeWeightProfit = (event: ChangeEvent<HTMLInputElement>) => {
    weightProfit.current = event.target.value.trim();
  };

  const onChangeAttachedMenuId = (event: ChangeEvent<HTMLSelectElement>) => {
    attachedMenuId.current = event.target.value;
  };

  const onChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    menuDescription.current = event.target.value.trim();
  };

  const onChangeKaspiPrice = (event: ChangeEvent<HTMLInputElement>) => {
    kaspiPrice.current = Number(event.target.value.trim()) || null;
  };

  const onChangePublic = () => {
    setIsPublic((prev) => !prev);
  };

  const onChangeAccessType = () => {
    const newValue =
      accessType === AccessTypes.paid ? AccessTypes.free : AccessTypes.paid;
    setAccessType(newValue);
    if (newValue === AccessTypes.free) {
      purchaseId.current = null;
    } else {
      purchaseId.current = originPurchaseId || '';
    }
  };

  const addNewDay = () => {
    const newDay = {
      day: {
        dayId: nanoid(),
        value: menuSet.length + 1,
        isPaid: accessType === AccessTypes.paid,
      },
      products: [],
    };
    setMenuSet([...menuSet, newDay]);
  };

  const deleteDay = (dayId: string) => {
    const filtered = menuSet.filter((item) => item.day?.dayId !== dayId);
    filtered.forEach((item, index) => {
      if (item.day) {
        item.day.value = index + 1;
      }
    });
    setMenuSet(filtered);
  };

  const deleteProduct = useDeleteProduct({ menuSet, setMenuSet, deleteDay });

  const { addRecipe, loading: pendingRecipeData } = useAddRecipe({
    menuSet,
    setMenuSet,
  });

  const { addProduct, loading: productsLoading } = useAddProduct({
    menuSet,
    setMenuSet,
  });

  const onChangePaidDay = (dayId: string) => {
    const targetDay = menuSet.find((item) => item.day?.dayId === dayId);
    if (targetDay?.day) {
      targetDay.day.isPaid = !targetDay.day.isPaid;
      setMenuSet([...menuSet]);
    }
  };

  const onChangeProductDescription = ({
    event,
    dayId,
    internalId,
  }: {
    event: ChangeEvent<HTMLTextAreaElement>;
    dayId: string;
    internalId: string;
  }) => {
    const value = event.target.value;
    const product = findProduct({ menuSet, dayId, internalId });

    if (product) {
      product.productDescription = value;
    }
  };

  const onChangeMealtime =
    ({
      mealtime,
      dayId,
      internalId,
    }: {
      mealtime: MealtimeProps;
      dayId: string;
      internalId: string;
    }) =>
    () => {
      const product = findProduct({ menuSet, dayId, internalId });

      if (product) {
        const hasItem = product.mealtimes?.some(
          (item) => item.time === mealtime.time
        );

        if (hasItem) {
          product.mealtimes = product.mealtimes?.filter(
            (item) => item.time !== mealtime.time
          );
        } else {
          product.mealtimes = [...(product.mealtimes || []), mealtime];
        }

        setMenuSet([...menuSet]);
      }
    };

  const onChangeWeight = ({
    event,
    dayId,
    internalId,
  }: {
    event: ChangeEvent<HTMLInputElement>;
    dayId: string;
    internalId: string;
  }) => {
    const weight = Number(event.target.value);
    const product = findProduct({ menuSet, dayId, internalId });

    if (product && isFinite(weight)) {
      const calculated = calcNutrients({
        proteins: product.proteins,
        fat: product.fat,
        carbohydrates: product.carbohydrates,
        kcal: product.kcal,
        weight,
      });

      product.weight = weight;
      product.calculatedNutrients = {
        ...calculated,
      };

      setMenuSet([...menuSet]);
    }
  };

  const totalMenu = useTotalMenu({ menuSet });
  const imageSrc = `${IMAGE_ENDPOINT}/${MENU_IMG_PATH}/${imageName}`;

  const purchaseIds = useMemo(() => {
    const filteredData = menusOriginData?.filter(
      (data) => data.purchaseId !== originPurchaseId
    );
    const purchaseIds = filteredData?.map((data) => ({
      value: data.purchaseId,
      title: data.menuName,
    }));
    return [{ value: '', title: '' }, ...(purchaseIds || [])];
  }, [menusOriginData, originPurchaseId]);

  return (
    <div className={modalStyles.modalWrapper}>
      <Loader visible={pendingRecipeData || productsLoading || saveLoading} />
      <div className={modalStyles.header}>
        <div className={commonStyles.imageBlock}>
          <div className={commonStyles.imageWrapper}>
            {!!imageName && <img src={imageSrc} alt="image" />}
          </div>
          <UploadImage imageType={ImageType.menu} setImageName={setImageName} />
        </div>
        <div className={modalStyles.leftSide}>
          <div className={commonStyles.plainRow}>
            <input
              type="number"
              className="input-order"
              defaultValue={String(menuOrder.current)}
              onChange={onChangeMenuOrder}
            />
            <span>
              <b>order</b>
            </span>
          </div>
          <label className={modalStyles.checkBoxBlock}>
            <input
              type="checkbox"
              onChange={onChangeMenuGift}
              checked={subscriptionGift}
            />
            Subscription Gift
          </label>
          <RadioGroup
            idLeft="publicAcess"
            checkedLeft={isPublic}
            textLeft="Public access"
            idRight="private"
            checkedRight={!isPublic}
            textRight="Private"
            name="public_type"
            onChange={onChangePublic}
          />
          <RadioGroup
            idLeft="paid"
            checkedLeft={accessType === AccessTypes.paid}
            textLeft="Paid"
            idRight="free"
            checkedRight={accessType === AccessTypes.free}
            textRight="Free"
            name="access_type"
            onChange={onChangeAccessType}
          />
          <div
            className={cn(
              'grid-2-Columns-1-3',
              modalStyles.inputBlock,
              modalStyles.mediumInputWidth
            )}
          >
            <label>Price</label>
            <input
              type="number"
              className={modalStyles.flexInput}
              onChange={onChangeKaspiPrice}
              defaultValue={kaspiPrice.current || ''}
            />
          </div>
          {accessType === AccessTypes.paid && (
            <div
              className={cn(
                'grid-2-Columns-1-3',
                modalStyles.inputBlock,
                modalStyles.mediumInputWidth
              )}
            >
              <label>purchase ID</label>
              <input
                type="text"
                className={modalStyles.flexInput}
                onChange={onChangePurchaseId}
                defaultValue={purchaseId.current || ''}
                disabled={!purchaseIdEdit}
              />
              <InputButton
                text="Edit"
                handler={editPurchaseIdHandler}
                brand={purchaseIdEdit}
              />
            </div>
          )}
        </div>
      </div>
      <form className={modalStyles.form}>
        <div className={cn('grid-2-Columns-1-3', modalStyles.inputBlock)}>
          <label>Name</label>
          <input
            type="text"
            onChange={onChangeMenuName}
            defaultValue={initialName}
          />
        </div>
        <div className={cn('grid-2-Columns-1-3', modalStyles.inputBlock)}>
          <label>Short name</label>
          <input
            type="text"
            onChange={onChangeMenuShortName}
            defaultValue={initialShortName}
          />
        </div>
        <div className={cn('grid-2-Columns-1-3', modalStyles.inputBlock)}>
          <label>Weight profit</label>
          <input
            type="text"
            onChange={onChangeWeightProfit}
            defaultValue={initialWeightProfit}
          />
        </div>
        {!!purchaseIds?.length && (
          <div className={cn('grid-2-Columns-1-3', modalStyles.inputBlock)}>
            <label>Attached menu ID</label>
            <select onChange={onChangeAttachedMenuId}>
              {purchaseIds.map((item) => (
                <option key={item.value} value={item.value || ''}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={cn('grid-2-Columns-1-3', modalStyles.textAreaBlock)}>
          <label>Description</label>
          <textarea
            onChange={onChangeDescription}
            defaultValue={menuDescription.current}
          />
        </div>
        {menuSet.map(({ day, products }) => {
          const totalDay = calcTotalDayNutrients(products as DisplayProduct[]);
          if (day) {
            return (
              <fieldset key={day.dayId} className={modalStyles.fieldset}>
                <CrossButton handler={() => deleteDay(day.dayId)} />
                <legend>
                  <span>Day</span>
                  <InputOrder
                    defaultValue={day.value}
                    onInputChange={onChangeInputDay({ dayId: day.dayId })}
                    onSort={onSortDays}
                  />
                </legend>
                {accessType === AccessTypes.paid && (
                  <RadioGroup
                    idLeft={`paid-day-${day.dayId}`}
                    checkedLeft={day.isPaid}
                    textLeft="paid"
                    idRight={`free-day-${day.dayId}`}
                    checkedRight={!day.isPaid}
                    textRight="free"
                    name={`is_paid_day_${day.dayId}`}
                    onChange={() => onChangePaidDay(day.dayId)}
                  />
                )}
                <div>
                  {!!products?.length && (
                    <div className={modalStyles.modalProducts}>
                      {products.map((product, index) => (
                        <div
                          key={product.internalId}
                          className={cn(
                            commonStyles.itemRow,
                            modalStyles.productRow,
                            {
                              [modalStyles.odd]: (index + 1) % 2,
                            }
                          )}
                        >
                          <div className={commonStyles.leftSide}>
                            <div className={modalStyles.deleteWrapper}>
                              <DeleteButton
                                handler={() =>
                                  deleteProduct({
                                    dayId: day?.dayId,
                                    internalId: product.internalId,
                                  })
                                }
                              />
                            </div>
                            <div className={modalStyles.orderBlock}>
                              <span>order</span>
                              <InputOrder
                                defaultValue={product.order}
                                onInputChange={onChangeInputProduct({
                                  dayId: day.dayId,
                                  internalId: product.internalId,
                                })}
                                onSort={onSortProducts}
                              />
                            </div>
                            <div className={modalStyles.productTextBlock}>
                              <span>{product.name}</span>
                              <textarea
                                onChange={(event) =>
                                  onChangeProductDescription({
                                    event,
                                    dayId: day?.dayId,
                                    internalId: product.internalId,
                                  })
                                }
                                defaultValue={product.productDescription}
                                rows={3}
                              />
                              <div className={modalStyles.checkBoxBlock}>
                                {mealtimes.map((item, index) => (
                                  <CheckBox
                                    key={index}
                                    initialChecked={
                                      !!product.mealtimes?.some(
                                        (mealtime) =>
                                          mealtime.time === item.time
                                      )
                                    }
                                    text={item.time}
                                    clickHandler={onChangeMealtime({
                                      mealtime: item,
                                      dayId: day.dayId,
                                      internalId: product.internalId,
                                    })}
                                    isReset={false}
                                    active
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className={commonStyles.rightSide}>
                            <div className={commonStyles.cell}>
                              <span className={commonStyles.title}>weight</span>
                              <input
                                type="number"
                                defaultValue={product.weight}
                                onChange={(event) =>
                                  onChangeWeight({
                                    event,
                                    dayId: day?.dayId,
                                    internalId: product.internalId,
                                  })
                                }
                              />
                            </div>
                            <NutrientsCell
                              proteins={product.calculatedNutrients.proteins}
                              fat={product.calculatedNutrients.fat}
                              carbohydrates={
                                product.calculatedNutrients.carbohydrates
                              }
                              kcal={product.calculatedNutrients.kcal}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <Total
                    title="Day total"
                    weight={totalDay.weight}
                    proteins={totalDay.proteins}
                    fat={totalDay.fat}
                    carbohydrates={totalDay.carbohydrates}
                    kcal={totalDay.kcal}
                  />
                </div>
                <div className={modalStyles.addProductBlock}>
                  <div
                    id={ADD_PRODUCT_ID}
                    className={modalStyles.addProductItem}
                  >
                    <input type="text" placeholder="product ID" />
                    <InputButton
                      text="Add product"
                      handler={(event) =>
                        addProduct({
                          event,
                          dayId: day.dayId,
                        })
                      }
                    />
                  </div>
                  <div
                    id={ADD_RECIPE_ID}
                    className={modalStyles.addProductItem}
                  >
                    <input type="text" placeholder="recipe ID" />
                    <InputButton
                      text="Add recipe"
                      handler={(event) =>
                        addRecipe({
                          event,
                          dayId: day.dayId,
                        })
                      }
                    />
                  </div>
                </div>
              </fieldset>
            );
          }
        })}
      </form>
      <Total
        title="Total"
        weight={totalMenu.weight}
        proteins={totalMenu.proteins}
        fat={totalMenu.fat}
        carbohydrates={totalMenu.carbohydrates}
        kcal={totalMenu.kcal}
        className={modalStyles.total}
      />
      <div className={modalStyles.footer}>
        <Button handler={addNewDay} blue>
          <span>New day</span>
        </Button>
        <div className={modalStyles.rightSide}>
          <Button handler={saveMenu}>
            <span>Save</span>
          </Button>
          <Button handler={onClose} outlined>
            <span>Cancel</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
