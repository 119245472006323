import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { fetchRecipesByIds } from './requests';
import { RecipeProps } from '../../common/types/recipes';
import { ResponseCustom } from '../types';

type UseFetchRecipesByIds = () => {
  fetchRecipesByIds: UseMutateAsyncFunction<
    ResponseCustom<RecipeProps[]>,
    unknown,
    string
  >;
  loading: boolean;
  recipes?: ResponseCustom<RecipeProps[]>;
};

export const useFetchRecipesByIds: UseFetchRecipesByIds = () => {
  const {
    mutateAsync,
    isPending,
    data: response,
  } = useMutation<ResponseCustom<RecipeProps[]>, unknown, string>({
    mutationFn: (recipeIds) => fetchRecipesByIds(recipeIds),
  });

  return {
    fetchRecipesByIds: mutateAsync,
    loading: isPending,
    recipes: response,
  };
};
