import cn from 'classnames';
import styles from '../../../common/pages/PrivacyPolicy/styles.module.scss';

export const textRu = (
  <>
    <h2 className={cn(styles.textCenter, styles.h1)}>eRates конвертер валют</h2>
    <h3 className={cn(styles.textCenter, styles.h2)}>Согалшение о платной подписке</h3>
    <p className={styles.mt1}>Последнее обновление 16 Марта, 2024</p>
    <p className={styles.mt1}>
      Эльвира Оспанова управляет веб-сайтом и мобильным приложением "eRates" (далее именуемые в
      дальнейшем "Сервис"). Данная политика распространяется на всю информацию, которая собирается
      или предоставляется через мобильное приложение "eRates".
    </p>
    <p className={styles.mt1}>
      Приобретая платную услугу пользователь дает свое согласие на следующие условия:
    </p>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        услуга "lifetime subscription" бессрочна (не имеет срока окончания) и оплачивается только
        один раз.
      </p>
    </div>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        услуги платной подписки, автоматически возобновляемой после окончания ее срока действия
        регулируются провайдерами ( поставщиками) взимания оплаты за такие услуги (App Store, Google
        play). Пользователь может отключить автопродление платной подписки в личном кабинете своего
        аккаунта, который (аккаунт) используется для входа в соответствующий магазин приложений (App
        Store, Google play).
      </p>
    </div>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        пользователь, приобретая данную услугу получает право на получение все платных возможностей
        приложения, включая отключение рекламы в приложении, а также получает доступ ко всем платным
        возможностям приложения, которые еще не опубликованы, но появятся позже.
      </p>
    </div>
    <div className={styles.markBlocK}>
      <span>-</span>
      <p>
        приложение "eRates" оставляет за собой право приостановить предоставление любых услуг
        пользователям в любое время, включая платные услуги, приобретенные пользователями ранее с
        действующим сроком предоставления услуги. Данное действие может наступить в случае несения
        экономических убытков владельцами приложения либо вызвано причинами, независящими от
        владельца приложения, такими как: действия третьих лиц, задействованных в предоставлении
        услуг пользователю приложения (App Store, Google play, API и прочие услуги третьх лиц),
        препятствующих предоставлению услуги ( прекращение деятельности, технические сбои и прочее),
        форс-мажорных обстоятельств.
      </p>
    </div>
  </>
);
