import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { deletePromocode } from './requests';
import { ResponseCustom } from '../types';

type UseDeletePromocode = () => {
  deletePromocode: UseMutateAsyncFunction<ResponseCustom<any>, unknown, string>;
  loading: boolean;
};

export const useDeletePromocode: UseDeletePromocode = () => {
  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<any>,
    unknown,
    string
  >({
    mutationFn: (documentId) => deletePromocode(documentId),
  });

  return {
    deletePromocode: mutateAsync,
    loading: isPending,
  };
};
