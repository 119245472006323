import styles from './styles.module.scss';
import { TextArea } from '../../shared/TextArea';
import { InputButton } from '../../shared/InputButton';
import { useFetchPickedTokens } from '../../requests/messages/useFetchPickedTokens';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { Button } from '../../../common/shared/Button';
import { useSendMessages } from '../../requests/messages/useSendMessages';
import { ConfirmModal } from '../../components/ConfirmModal';
import { SearchInput } from '../../components/SearchInput';
import { getCurrentDateHour } from '../../common/utils/date';
import { BannerImage } from './ui/BannerImage';
import { TextColor } from './ui/TextColor';
import { ButtonType } from './ui/ButtonType';
import cn from 'classnames';
import { MessageAppRoutesType, MessageButtonType } from './types';
import { AppRoute } from './ui/AppRoute';
import { Countries } from './ui/Countries';

export const MessagesPage = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [customerIds, setCustomerIds] = useState<string>('');
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const titleRef = useRef<string>('');
  const messagesRef = useRef<string>('');
  const routeRef = useRef<MessageAppRoutesType | null>(null);
  const textColor = useRef<string>('#140000');
  const buttonType = useRef<MessageButtonType>('brand');

  const handleTitleValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    titleRef.current = value;
  };

  const handleMessageValue = (value: string) => {
    messagesRef.current = value;
  };

  const handleRoute = (event: ChangeEvent<HTMLSelectElement>) => {
    routeRef.current = (event.target.value ||
      null) as MessageAppRoutesType | null;
  };

  const handleTextColor = (event: ChangeEvent<HTMLSelectElement>) => {
    textColor.current = event.target.value;
  };

  const handleButtonType = (event: ChangeEvent<HTMLSelectElement>) => {
    buttonType.current = event.target.value as MessageButtonType;
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal((prev) => !prev);
  };

  const handleCustomerIds = (value: string) => {
    setCustomerIds(value);
  };

  const { sendMessages, loading: loadingSend, response } = useSendMessages();

  const {
    fetchPickedTokens,
    loading: loadingUsers,
    users,
  } = useFetchPickedTokens();

  const messagesTokens = useMemo(() => {
    if (users?.length) {
      return users.map((user) => user.meta?.messageToken || '');
    }
  }, [users]);

  const onCheckUsersIds = async () => {
    const customerIdsArray = customerIds.split(',').map((item) => item.trim());
    await fetchPickedTokens({ customerIds: customerIdsArray });
  };

  const onSendMessages = async () => {
    toggleConfirmModal();
    const messageCustomId = getCurrentDateHour();
    if (messagesTokens) {
      await sendMessages({
        tokens: messagesTokens,
        messageCustomId,
        title: titleRef.current,
        message: messagesRef.current,
        imageUrl: image,
        backgroundUrl: backgroundImage,
        route: routeRef.current,
        textColor: textColor.current,
        buttonType: buttonType.current,
      });
    }
  };

  const hasTokens = messagesTokens?.every((token) => !!token);
  const hasTitle = !!titleRef.current;
  const hasMessage = !!messagesRef.current;

  const sendDisabled = !hasTokens || !hasTitle || !hasMessage;
  const loading = loadingSend || loadingUsers;

  return (
    <div
      className={cn(
        'admin-page-wrapper',
        'page-layout-fixed-height',
        styles.page
      )}
    >
      <div className={styles.blockStandard}>
        <div className={styles.blockMicro}>
          <h4>Title</h4>
          <SearchInput onInputChange={handleTitleValue} />
        </div>
        <div className={styles.blockMicro}>
          <h4>Message</h4>
          <div>
            <TextArea
              handler={handleMessageValue}
              initialValue={''}
              isReset={false}
              rows={3}
              active
            />
          </div>
        </div>
        <div className={styles.blockMicro}>
          <BannerImage
            handler={setBackgroundImage}
            imageUrl={backgroundImage}
          />
        </div>
        <div className={styles.blockMicro}>
          <BannerImage
            handler={setImage}
            imageUrl={image}
            isBackgroundImage={false}
          />
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockMicro}>
            <AppRoute handler={handleRoute} />
          </div>
          <div className={styles.blockMicro}>
            <TextColor handler={handleTextColor} />
          </div>
          <div className={styles.blockMicro}>
            <ButtonType handler={handleButtonType} />
          </div>
        </div>
      </div>
      <div className={styles.blockMicro}>
        <h2>Pick users</h2>
        <div>
          <TextArea
            handler={handleCustomerIds}
            initialValue={customerIds}
            isReset={false}
            active
          />
        </div>
        <div>
          <InputButton
            text="Check"
            handler={onCheckUsersIds}
            disabled={loading || !customerIds}
          />
        </div>
      </div>
      <div className={styles.blockMicro}>
        <Countries />
      </div>
      {!!users?.length && (
        <div className={styles.blockStandard}>
          <h2>Charge users</h2>
          <ul className={styles.resultBlock}>
            {users.map((user) => (
              <li key={user.customerId} className={styles.stringRow}>
                <span className="bold">{user.profile?.name}</span>
                <span>{user.profile?.age} year</span>
                <span>{user.meta?.country}</span>
              </li>
            ))}
          </ul>
          <div>
            <Button
              disabled={loading || sendDisabled}
              handler={toggleConfirmModal}
            >
              <span>SEND NOTIFICATIONS</span>
            </Button>
          </div>
        </div>
      )}
      {showConfirmModal && (
        <ConfirmModal
          onCloseModal={toggleConfirmModal}
          confirmHandler={onSendMessages}
          cancelHandler={toggleConfirmModal}
        />
      )}
    </div>
  );
};
