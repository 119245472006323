import React, { FC } from 'react';
import styles from './styles.module.scss';

interface Props {
  handler: () => void;
}

export const CrossButton: FC<Props> = ({ handler }) => {
  return (
    <button className={styles.crossButton} onClick={handler} type="button">
      X
    </button>
  );
};
