import { useFetchProductsByIds } from '../../requests/products/useFetchProductsByIds';
import { useFetchRecipesByIds } from '../../requests/recipes/useFetchRecipesByIds';
import { useCallback } from 'react';
import { RecipeProps } from '../types/recipes';
import { CalcRecipeNutrients, DisplayProduct } from '../types/menu';
import {
  calcRecipeNutrientsDefault,
  calcTotalRecipeNutrients,
  formatRecipe,
} from '../../components/Menu/utils';

type UseCalcRecipeNutrients = () => {
  calcRecipeNutrients: CalcRecipeNutrients;
  loading: boolean;
};

type FetchRecipeCallback = ({
  internalId,
  order,
  recipes,
  weight,
}: {
  internalId: string;
  order: number;
  recipes: RecipeProps[];
  weight: number;
}) => Promise<DisplayProduct | void>;

export const useCalcRecipeNutrients: UseCalcRecipeNutrients = () => {
  const { fetchRecipesByIds, loading: recipeLoading } = useFetchRecipesByIds();
  const { fetchProductsByIds, loading: productsLoading } =
    useFetchProductsByIds();

  const fetchRecipeCallback = useCallback<FetchRecipeCallback>(
    async ({ internalId, order, recipes, weight }) => {
      const recipe = recipes[0];
      const productsIds = recipe.products.map((product) => product.prodId);

      const response = await fetchProductsByIds(productsIds);

      if (response && response.data) {
        const totalNutrients = calcTotalRecipeNutrients({
          stockProducts: response.data,
          recipe,
        });
        const defaultNutrientsData = calcRecipeNutrientsDefault({
          totalNutrients,
        });

        return formatRecipe({
          internalId,
          order,
          recipe,
          defaultNutrientsData,
          weight,
        });
      }
    },
    [fetchProductsByIds]
  );

  const calcRecipeNutrients: CalcRecipeNutrients = useCallback(
    async ({ internalId, recipeId, order, weight }) => {
      const response = await fetchRecipesByIds(recipeId);

      if (response?.data) {
        return await fetchRecipeCallback({
          internalId,
          order,
          recipes: response?.data,
          weight,
        });
      }
    },
    [fetchRecipesByIds, fetchRecipeCallback]
  );

  return {
    calcRecipeNutrients,
    loading: recipeLoading || productsLoading,
  };
};
