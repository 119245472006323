import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '../../../common/shared/Button';
import cn from 'classnames';
import styles from '../../../common/styles/header.module.scss';
import adminStyles from './styles.module.scss';

export const Header = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token');
    navigate('/kcal');
  };

  return (
    <header className={styles.header}>
      <div className={cn(styles.container, adminStyles.container)}>
        <div className={adminStyles.navWrapper}>
          <NavLink className={styles.logoLink} to="/admin">
            <img
              className={styles.logo}
              src="/assets/images/fire-40px.png"
              alt="logo"
            />
          </NavLink>
          <nav className={adminStyles.navigation}>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-messages"
            >
              Messages
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-users"
            >
              Users
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-products-search"
            >
              Products Search
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-products-list"
            >
              Products List
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-recipes"
            >
              Recipes
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-menu"
            >
              Menu
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? adminStyles.active : '')}
              to="/admin-user-products"
            >
              User products
            </NavLink>
          </nav>
        </div>
        <Button handler={logout} outlined>
          <span>exit</span>
        </Button>
      </div>
    </header>
  );
};
