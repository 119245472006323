import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from '../../../common/styles/header.module.scss';

export const HeaderKcal = () => {
  return (
    <header className={styles.header}>
      <div className={cn(styles.container, 'container')}>
        <Link className={styles.logoLink} to="/kcal">
          <img className={styles.logo} src="/assets/images/fire-40px.png" alt="kCal ELYapp" />
          <p className={styles.text}>kCal ELYapp</p>
        </Link>
      </div>
    </header>
  );
};
