import { SearchResultsItemMeta } from './search';
import { ProductsPropsWithDbId } from './products';

export enum SubscriptionKind {
  AdFree = 'AdFree',
  Royal = 'Royal',
}

export interface PromoCodeProps {
  kind: SubscriptionKind;
  endDate: number;
}

export enum FeedSystem {
  Balanced = '30/20/50',
  LowProtein = '15/20/65',
  LowFat = '40/15/45',
  LowCarbs = '65/20/15',
}

export enum ChangeWeightTemp {
  maintain = 'maintain',
  low = 'low',
  moderate = 'moderate',
  fast = 'fast',
  extraFast = 'extraFast',
}

export enum WaterLevelSystem {
  auto = 'auto',
  custom = 'custom',
}

export enum ActivityLevel {
  Non = 1,
  Average = 1.2,
  Low = 1.375,
  Middle = 1.55,
  High = 1.725,
  ExtraHigh = 1.9,
}

export interface UserSearchProps {
  id: { raw: string };
  customerId?: { raw: string; snippet: string };
  historyId?: { raw: string };
  firstInstallTime?: { raw: number };
  'promoCode.kind'?: { raw: 'AdFree' | 'Royal'; snippet: string };
  'promoCode.endDate'?: { raw: number };
  entitlements?: { raw: string[]; snippet: string };
  'userPersonalData.email'?: { raw: string; snippet: string };
  'userPersonalData.familyName'?: { raw: string; snippet: string };
  'userPersonalData.givenName'?: { raw: string; snippet: string };
  'userPersonalData.OS'?: { raw: string; snippet: string };
  'profile.ava'?: { raw: string };
  'profile.name'?: { raw: string; snippet: string };
  'profile.gender'?: { raw: string; snippet: string };
  'profile.height'?: { raw: string; snippet: string };
  'profile.weightCurrent'?: { raw: string; snippet: string };
  'profile.weightTarget'?: { raw: string; snippet: string };
  'profile.age'?: { raw: string; snippet: string };
  'profile.activityLevel'?: { raw: ActivityLevel };
  'profile.feedSystem'?: { raw: FeedSystem };
  'profile.changeWeightTemp'?: { raw: ChangeWeightTemp };
  'profile.waterSystem'?: { raw: WaterLevelSystem };
  'profile.waterLevel'?: { raw: string };
  'ownProducts.name'?: { raw: string | string[]; snippet: string };
  favouriteProductsIds?: { raw: string[] };
  'meta.firstInstallTime'?: { raw: number };
  'meta.lastVisited'?: { raw: number };
  'meta.country'?: { raw: string; snippet: string };
  'meta.languages'?: { raw: string[] };
  'meta.subscriptionStoreEndDateMillis'?: { raw: number };
  'meta.subscriptionStoreType'?: { raw: string; snippet: string };
  _meta: SearchResultsItemMeta;
}

export enum Gender {
  male = 'male',
  female = 'female',
}

export interface PromoCodeData {
  kind: SubscriptionKind;
  endDate: number;
}

export interface UserPersonalData {
  email: string;
  familyName: string;
  givenName: string;
  OS?: string;
}

export interface UserMetaProps {
  firstInstallTime: number;
  lastVisited: number;
  country?: string;
  languages?: string[];
  messageToken?: string;
  notificationsEnabled?: boolean;
  subscriptionStoreEndDateMillis?: number;
  subscriptionStoreType?: string;
  customerIdOld?: string[];
  deletedAccount?: boolean;
}

export interface UserProfileProps {
  gender: Gender;
  height: string;
  weightCurrent: string;
  weightTarget: string;
  age: string;
  activityLevel: ActivityLevel;
  feedSystem: FeedSystem;
  changeWeightTemp: ChangeWeightTemp;
  waterSystem: WaterLevelSystem;
  waterLevel: string;
  ava?: string;
  name?: string;
}

export interface UserProps {
  _id: string;
  deviceId: string;
  customerId: string;
  userSub?: string;
  historyId: string;
  userPersonalData?: UserPersonalData;
  firstInstallTime?: number;
  freeAdPeriodEndDate: number;
  promoCode?: PromoCodeData;
  entitlements?: string[];
  profile?: UserProfileProps;
  ownProducts?: ProductsPropsWithDbId[];
  favouriteProductsIds?: string[];
  meta?: UserMetaProps;
}
