import { FC } from 'react';
import cn from 'classnames';
import { NutrientsCell } from './NutrientsCell';
import { TotalNutrientsProps } from '../../../common/types/menu';
import styles from './styles.module.scss';

interface Props extends TotalNutrientsProps {
  title: string;
  className?: string;
}

export const Total: FC<Props> = ({
  weight,
  proteins,
  fat,
  carbohydrates,
  kcal,
  title,
  className,
}) => {
  return (
    <div className={cn(styles.itemRow, styles.totalDay, title, className)}>
      <span>{title}: </span>
      <div className={styles.rightSide}>
        <div className={styles.cell}>
          <span className={styles.title}>weight</span>
          <span>{weight}</span>
        </div>
        <NutrientsCell
          proteins={proteins}
          fat={fat}
          carbohydrates={carbohydrates}
          kcal={kcal}
        />
      </div>
    </div>
  );
};
