import React from 'react';
import { useSearchProducts } from '../../requests/searchProducts/useSearchProducts';
import { ProductsSearch } from '../../components/ProductsSearch';
import SearchLayout from '../../entities/SearchLayout';

export const ProductsSearchPage = () => {
  const { searchProducts, data, loading } = useSearchProducts();
  return (
    <div className="admin-page-wrapper page-layout-fixed-height">
      <SearchLayout searchFunc={searchProducts} data={data} loading={loading}>
        {!!data && <ProductsSearch data={data} />}
      </SearchLayout>
    </div>
  );
};
