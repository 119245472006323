import React, { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface InputButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  handler: (event?: any) => void;
  className?: string;
  alert?: boolean;
  gray?: boolean;
  brand?: boolean;
  disabled?: boolean;
}

export const InputButton: FC<InputButtonProps> = ({
  text,
  handler,
  alert = false,
  gray = false,
  brand = false,
  className,
  disabled = false,
  ...props
}) => {
  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event: any) => {
    event.preventDefault();
    handler(event);
  };
  return (
    <button
      {...props}
      className={cn(
        styles.inputButton,
        className,
        { [styles.alert]: alert },
        { [styles.gray]: gray },
        { [styles.brand]: brand },
        { [styles.disabled]: disabled }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{text}</span>
    </button>
  );
};
