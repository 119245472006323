import { useFetchUsersCountries } from '../../requests/app/useFetchUserCountries';
import styles from './styles.module.scss';
import cn from 'classnames';

export const Dashboard = () => {
  const { countries } = useFetchUsersCountries();
  return (
    <section className={cn('admin-page-wrapper', 'page-layout-fixed-height')}>
      <h1>Dashboard</h1>
      {!!countries?.length && (
        <div className={styles.block}>
          <h2>Countries</h2>
          <ul className={styles.list}>
            {countries.map(({ country, count }) => (
              <li key={country} className={styles.listItem}>
                <span>{country}:</span>
                <span>{count}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
};
