import { useMemo, useState } from 'react';
import { CategoriesList, CategoryProps } from '../../components/CategoriesList';
import { useFetchMenus } from '../../requests/menu/useFetchMenus';
import { AccessTypes } from '../../common/types/menu';
import styles from './styles.module.scss';
import { RoundLabelAllow, RoundLabelPending } from '../../shared/RoundLabel';
import { Menu } from '../../components/Menu';

export interface MenusOriginData {
  menuName: string;
  purchaseId: string | null;
}

export const MenuPage = () => {
  const activeMenuIdStore = localStorage.getItem('activeMenuId') || '';
  const [activeMenuId, setActiveMenuId] = useState('');

  const { menusData, loading: fetchMenusLoading, refetch } = useFetchMenus();

  const menusOriginData = useMemo(() => {
    return menusData?.map((item) => ({
      _id: item._id,
      menuName: item.name,
      purchaseId: item.purchaseId,
    }));
  }, [menusData]);

  useMemo(() => {
    if (menusOriginData?.length) {
      const hasActiveMenuId = menusOriginData.find(
        (item) => item._id === activeMenuIdStore
      );
      if (hasActiveMenuId) {
        setActiveMenuId(activeMenuIdStore);
      } else {
        setActiveMenuId(menusOriginData[0]._id);
      }
    }
  }, [menusOriginData, activeMenuIdStore]);

  const currentMenuData = useMemo(() => {
    return menusData?.find((item) => item._id === activeMenuId);
  }, [menusData, activeMenuId]);

  const categoriesList = useMemo<CategoryProps<string>[] | undefined>(() => {
    return menusData
      ?.map((item) => ({
        _id: item._id,
        title: item.name,
        label: item.publicAccess ? RoundLabelAllow : RoundLabelPending,
        emoji: item.accessType === AccessTypes.paid ? '💰' : '🛟',
        secondEmoji: item.subscriptionGift ? '🎀' : undefined,
        order: item.order,
      }))
      .sort((a, b) => a.order - b.order);
  }, [menusData]);

  const onCategoryClick = (menuId: string) => {
    localStorage.setItem('activeMenuId', menuId);
    setActiveMenuId(menuId);
  };

  return (
    <div className="admin-page-wrapper">
      <CategoriesList
        clickHandler={onCategoryClick}
        activeCategory={activeMenuId}
        isLoading={fetchMenusLoading}
        categories={categoriesList}
        className={styles.categories}
      />
      {!!menusData?.length && !!currentMenuData && (
        <Menu
          menuData={currentMenuData}
          menusLength={menusData.length}
          loading={fetchMenusLoading}
          refetchMenus={refetch}
          menusOriginData={menusOriginData}
        />
      )}
    </div>
  );
};
