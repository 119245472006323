import React, { FC, useMemo, useState } from 'react';
import { getYyyyMmDd } from '../../common/utils/date';
import styles from './styles.module.scss';
import cn from 'classnames';
import { InputButton } from '../../shared/InputButton';
import { Modal } from '../../../common/shared/Modal';
import { useForm } from 'react-hook-form';
import PatternField from '../../../common/shared/SubjectFields/PatternField';
import { Button } from '../../../common/shared/Button';
import { validateSubscriptionDate } from '../../components/UsersElasticList/utils/validations';
import { getTimestampFromSubscriptionDate } from '../../components/UsersElasticList/utils/misc';
import { usePatchPromocode } from '../../requests/users/usePatchPromocode';
import { PromoCodeProps, SubscriptionKind } from '../../common/types/users';
import { Loader } from '../../../common/shared/Loader';
import { useDeletePromocode } from '../../requests/users/useDeletePromocode';
import { ConfirmModal } from '../../components/ConfirmModal';

interface FormProps {
  date: string;
}

interface Props {
  documentId: string;
  endDate?: number;
  refetch?: () => void;
}

export const Promocode: FC<Props> = ({ documentId, endDate, refetch }) => {
  const { patchPromocode, loading: patchLoading } = usePatchPromocode();
  const { deletePromocode, loading: deleteLoading } = useDeletePromocode();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const toggleCreateModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const toggleDeleteConfirmModal = () => {
    setDeleteConfirmModalOpen((prev) => !prev);
  };

  const date = useMemo(() => {
    if (endDate) {
      return getYyyyMmDd(endDate);
    }
    return '';
  }, [endDate]);

  const hasSubscription = useMemo(() => {
    if (endDate) {
      const currentDate = Date.now();
      return endDate >= currentDate;
    }
    return false;
  }, [endDate]);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<FormProps>({
    mode: 'onSubmit',
  });

  const onPatchPromocode = async (formProps: FormProps) => {
    const timestamp = getTimestampFromSubscriptionDate(formProps.date);
    const promoCode: PromoCodeProps = {
      kind: SubscriptionKind.Royal,
      endDate: timestamp,
    };
    await patchPromocode({ documentId, promoCode });
    refetch?.();
    toggleCreateModal();
  };

  const onDeletePromocode = async () => {
    await deletePromocode(documentId);
    refetch?.();
    toggleDeleteConfirmModal();
  };

  return (
    <>
      <Loader visible={patchLoading || deleteLoading} />
      <dl className={styles.row}>
        <dt>Промокот</dt>
        <dd>
          {!!date && (
            <div
              className={cn(
                styles.itemWidget,
                { [styles.success]: hasSubscription },
                { [styles.inactive]: !hasSubscription }
              )}
            >
              {date}
            </div>
          )}
          <InputButton text="Назначить промокот" handler={toggleCreateModal} />
          {!!date && (
            <InputButton
              text="Удалить промокот"
              handler={toggleDeleteConfirmModal}
              alert
            />
          )}
        </dd>
      </dl>
      {createModalOpen && (
        <Modal onClose={toggleCreateModal}>
          <div className={styles.modalWrapper}>
            <p className={styles.title}>Дата окончания промокода</p>
            <form onSubmit={handleSubmit(onPatchPromocode)}>
              <PatternField
                control={control}
                error={errors.date}
                id="promocode-date-input"
                inputClassName={styles.input}
                format="##-##-####"
                placeholder="__-__-____"
                mask="_"
                fieldName="date"
                label="Дата окончания"
                validate={validateSubscriptionDate}
              />
              <div className={styles.buttonWrapper}>
                <Button type="submit">Подтвердить</Button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      {deleteConfirmModalOpen && (
        <ConfirmModal
          onCloseModal={toggleDeleteConfirmModal}
          confirmHandler={onDeletePromocode}
          cancelHandler={toggleDeleteConfirmModal}
        />
      )}
    </>
  );
};
