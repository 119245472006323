import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { Input } from '../../../Admin/shared/Input';
import { SubjectFieldProps } from './types';

interface Props extends SubjectFieldProps {
  format: string;
  mask?: string;
}

const PatternField: FC<Props> = ({
  control,
  format,
  mask = '',
  error,
  id,
  fieldName,
  disabled = false,
  label,
  initialValue = '',
  valueHandler,
  validate,
  invalidMessage,
  minLength,
  minValue,
  maxValue,
  minValueMessage,
  maxValueMessage,
  placeholder,
  allowEmptyFormatting = false,
  inputClassName,
}) => {
  const requiredMessage = 'Введите значение';
  const invalidFallbackMessage = 'Некорректное значение';
  const minLengthMessage = `Минимальное количество символов: ${minLength}`;
  const maxValueFallbackMessage = `Максимальное значение: ${maxValue}`;
  const minValueFallbackMessage = `Минимальное значение: ${minValue}`;

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: {
          value: true,
          message: requiredMessage,
        },
        minLength: minLength && {
          value: minLength,
          message: minLengthMessage,
        },
        min: minValue && {
          value: minValue,
          message: minValueMessage ?? minValueFallbackMessage,
        },
        max: maxValue && {
          value: maxValue,
          message: maxValueMessage ?? maxValueFallbackMessage,
        },
        validate: (value) => {
          if (validate) {
            const isValid = validate(value);
            if (!isValid) {
              return invalidMessage ?? invalidFallbackMessage;
            }
          }
        },
      }}
      render={({ field }) => (
        <PatternFormat
          type="tel"
          id={id}
          className={inputClassName}
          format={format}
          mask={mask}
          label={label}
          value={field.value}
          onChange={field.onChange}
          disabled={disabled}
          error={!!error}
          helperText={error?.message}
          customInput={Input}
          placeholder={placeholder}
          allowEmptyFormatting={allowEmptyFormatting}
          initialValue={initialValue}
          handler={() => {
            return valueHandler || null;
          }}
          isReset={false}
          active={true}
        />
      )}
    />
  );
};

export default PatternField;
