import { useSearchElasticUsers } from '../../../../requests/elasticSearch/useSearchElasticUsers';
import { useFetchMenus } from '../../../../requests/menu/useFetchMenus';
import { useMemo } from 'react';
import SearchLayout from '../../../../entities/SearchLayout';
import { UsersElasticList } from '../../../../components/UsersElasticList';

export const ElasticSearch = () => {
  const {
    searchElasticUsers,
    data: usersElasticList,
    loading,
  } = useSearchElasticUsers();

  const { menusData } = useFetchMenus();

  const menuIds = useMemo(() => {
    return menusData?.map((item) => item.purchaseId);
  }, [menusData]);

  return (
    <SearchLayout
      searchFunc={searchElasticUsers}
      data={usersElasticList}
      loading={loading}
    >
      <>
        {!!usersElasticList && (
          <UsersElasticList
            usersList={usersElasticList.results}
            menuIds={menuIds}
          />
        )}
      </>
    </SearchLayout>
  );
};
