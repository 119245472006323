import { useState } from 'react';
import { ProductCategories } from '../../components/ProductCategories';
import { ProductsList } from '../../components/ProductsList';
import { DEFAULT_PRODUCT_CATEGORY } from '../../common/constants/common';

export const ProductsListPage = () => {
  const currentCategory =
    localStorage.getItem('currentProductCategory') || DEFAULT_PRODUCT_CATEGORY;
  const [activeCategory, setActiveCategory] = useState<string>(currentCategory);

  return (
    <div className="admin-page-wrapper">
      <ProductCategories
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <ProductsList category={activeCategory} />
    </div>
  );
};
