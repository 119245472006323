import { Select } from '../../../shared/Select';
import { useFetchUsersCountries } from '../../../requests/app/useFetchUserCountries';
import { ChangeEvent, useMemo, useState } from 'react';
import { SelectOptionsProps } from '../../../shared/Select/types';

export const Countries = () => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  const { countries } = useFetchUsersCountries();

  const selectOptions: SelectOptionsProps[] | undefined = useMemo(() => {
    if (countries?.length) {
      return countries.map(({ country, count }) => ({
        title: `${country} - ${count}`,
        value: country,
      }));
    }
  }, [countries]);

  const handleCountry = (event: ChangeEvent<HTMLSelectElement>) => {
    console.log('event', event);
  };

  return (
    <>
      {!!selectOptions?.length && (
        <>
          <h2>Countries</h2>
          <div>
            <Select options={selectOptions} onChange={handleCountry} />
          </div>
          <div>
            {/*<InputButton text="Check" handler={onPickUsers} disabled={loading} />*/}
          </div>
        </>
      )}
    </>
  );
};
