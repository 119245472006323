import {
  DisplayProduct,
  MenuSet,
  ProductType,
  TotalNutrientsProps,
} from '../../../common/types/menu';
import {
  NutrientsProps,
  ProductsPropsWithDbId,
} from '../../../common/types/products';
import { RecipeProps } from '../../../common/types/recipes';
import { calcNutrients } from '../../../common/utils/calcNutrients';
import { DEFAULT_PRODUCT_WEIGHT } from '../../../common/constants/products';
import { Dispatch, SetStateAction } from 'react';

const DEFAULT_WEIGHT = DEFAULT_PRODUCT_WEIGHT;

type CalcTotalDayNutrients = (
  products: DisplayProduct[]
) => TotalNutrientsProps;

export const calcTotalDayNutrients: CalcTotalDayNutrients = (products) => {
  return products.reduce(
    (acc, item) => {
      return {
        proteins: item.calculatedNutrients.proteins + acc.proteins,
        fat: item.calculatedNutrients.fat + acc.fat,
        carbohydrates:
          item.calculatedNutrients.carbohydrates + acc.carbohydrates,
        kcal: item.calculatedNutrients.kcal + acc.kcal,
        weight: item.weight + acc.weight,
      };
    },
    {
      proteins: 0,
      fat: 0,
      carbohydrates: 0,
      kcal: 0,
      weight: 0,
    } as TotalNutrientsProps
  );
};

export const calcTotalRecipeNutrients = ({
  stockProducts,
  recipe,
}: {
  stockProducts: ProductsPropsWithDbId[];
  recipe: RecipeProps;
}): TotalNutrientsProps => {
  return stockProducts.reduce((acc, product) => {
    const recipeProduct = recipe.products.find(
      (item) => item.prodId === product._id
    );

    const weight = Number(recipeProduct?.amount || '0');

    const { proteins, fat, carbohydrates, kcal } = calcNutrients({
      proteins: Number(product.proteins) || 0,
      carbohydrates: Number(product.carbohydrates) || 0,
      fat: Number(product.fat) || 0,
      kcal: Number(product.kcal) || 0,
      weight,
    });

    return {
      proteins: proteins + (acc.proteins || 0),
      fat: fat + (acc.fat || 0),
      carbohydrates: carbohydrates + (acc.carbohydrates || 0),
      kcal: kcal + (acc.kcal || 0),
      weight: weight + (acc.weight || 0),
    };
  }, {} as TotalNutrientsProps);
};

type CalcRecipeNutrientsDefault = ({
  totalNutrients,
}: {
  totalNutrients: TotalNutrientsProps;
}) => NutrientsProps;

export const calcRecipeNutrientsDefault: CalcRecipeNutrientsDefault = ({
  totalNutrients,
}) => {
  return {
    proteins:
      (totalNutrients.proteins / totalNutrients?.weight) *
      DEFAULT_PRODUCT_WEIGHT,
    fat: (totalNutrients.fat / totalNutrients.weight) * DEFAULT_PRODUCT_WEIGHT,
    carbohydrates:
      (totalNutrients.carbohydrates / totalNutrients.weight) *
      DEFAULT_PRODUCT_WEIGHT,
    kcal:
      (totalNutrients.kcal / totalNutrients.weight) * DEFAULT_PRODUCT_WEIGHT,
  };
};

type FormatRecipe = ({
  internalId,
  order,
  recipe,
  defaultNutrientsData,
  weight,
}: {
  internalId: string;
  order: number;
  recipe: RecipeProps;
  defaultNutrientsData: NutrientsProps;
  weight: number;
}) => DisplayProduct;

export const formatRecipe: FormatRecipe = ({
  internalId,
  order,
  recipe,
  defaultNutrientsData,
  weight,
}) => {
  return {
    _id: recipe._id,
    internalId,
    order,
    productType: ProductType.recipe,
    name: recipe.name,
    weight: DEFAULT_WEIGHT,
    proteins: defaultNutrientsData.proteins,
    fat: defaultNutrientsData.fat,
    carbohydrates: defaultNutrientsData.carbohydrates,
    kcal: defaultNutrientsData.kcal,
    calculatedNutrients: calcNutrients({
      proteins: defaultNutrientsData.proteins,
      fat: defaultNutrientsData.fat,
      carbohydrates: defaultNutrientsData.carbohydrates,
      kcal: defaultNutrientsData.kcal,
      weight,
    }),
  };
};

type FormatProduct = ({
  internalId,
  order,
  product,
  weight,
}: {
  internalId: string;
  order: number;
  product: ProductsPropsWithDbId;
  weight: number;
}) => DisplayProduct;

export const formatProduct: FormatProduct = ({
  internalId,
  order,
  product,
  weight,
}) => {
  return {
    _id: product._id,
    internalId,
    order,
    productType: ProductType.product,
    name: product.name,
    weight: DEFAULT_WEIGHT,
    proteins: Number(product.proteins || '0'),
    fat: Number(product.fat || '0'),
    carbohydrates: Number(product.carbohydrates || '0'),
    kcal: Number(product.kcal || '0'),
    calculatedNutrients: calcNutrients({
      proteins: Number(product.proteins || '0'),
      fat: Number(product.fat || '0'),
      carbohydrates: Number(product.carbohydrates || '0'),
      kcal: Number(product.kcal || '0'),
      weight,
    }),
  };
};

type SetDisplayProduct = ({
  dayId,
  product,
  menuSet,
  setMenuSet,
}: {
  dayId: string;
  product: DisplayProduct;
  menuSet: MenuSet[];
  setMenuSet: Dispatch<SetStateAction<MenuSet[]>>;
}) => void;

export const setDisplayProduct: SetDisplayProduct = ({
  dayId,
  product,
  menuSet,
  setMenuSet,
}) => {
  const currentMenuDay = menuSet.find((item) => item.day?.dayId === dayId);

  if (currentMenuDay) {
    currentMenuDay.products = [...(currentMenuDay.products || []), product];
    setMenuSet([...menuSet]);
  }
};

type GetLastOrderProduct = ({
  menuSet,
  dayId,
}: {
  menuSet: MenuSet[];
  dayId: string;
}) => number;

export const getLastOrderProduct: GetLastOrderProduct = ({
  menuSet,
  dayId,
}) => {
  const menuDay = menuSet.find((item) => item.day?.dayId === dayId);
  return (menuDay?.products?.[menuDay?.products?.length - 1]?.order || 0) + 1;
};

type FindProduct = ({
  menuSet,
  dayId,
  internalId,
}: {
  menuSet: MenuSet[];
  dayId: string;
  internalId: string;
}) => DisplayProduct | undefined;

export const findProduct: FindProduct = ({ menuSet, dayId, internalId }) => {
  return menuSet
    .find((item) => item.day?.dayId === dayId)
    ?.products?.find((product) => product.internalId === internalId);
};
