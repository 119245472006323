import { ChangeEvent, FC, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface InputProps {
  initialValue: string;
  handler: (value: string) => void;
  isReset: boolean;
  active: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  className?: string;
  type?: 'text' | 'number';
}

export const Input: FC<InputProps> = ({
  initialValue,
  handler,
  isReset,
  active,
  error = false,
  helperText,
  className,
  type = 'text',
  ...props
}) => {
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    if (isReset) {
      setValue(initialValue);
    }
  }, [isReset, initialValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
    handler(value);
  };

  return (
    <div className={styles.inputWrapper}>
      <input
        className={cn(
          styles.input,
          className,
          { [styles.active]: active },
          { [styles.error]: error }
        )}
        type={type}
        readOnly={!active}
        value={value}
        onChange={onChange}
        {...props}
      />
      {error && <span className={styles.helperText}>{helperText}</span>}
    </div>
  );
};
