import { Tabs } from '../../shared/Tabs';
import { TabDataProps } from '../../shared/Tabs/types';
import { ElasticSearch } from './ui/Elastic';
import { MongoSearch } from './ui/Mongo';

export const UsersPage = () => {
  const data: TabDataProps[] = [
    {
      id: 'mongo',
      label: 'Mongo',
      children: <MongoSearch />,
    },
    {
      id: 'elastic',
      label: 'Elastic',
      children: <ElasticSearch />,
    },
  ];

  return (
    <div className="admin-page-wrapper page-layout-fixed-height">
      <Tabs data={data} />
    </div>
  );
};
