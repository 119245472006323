import { ButtonLink } from '../../../common/shared/ButtonLink';
import styles from './styles.module.scss';

export const Banner = () => {
  return (
    <div className={styles.homeBanner}>
      <div className={styles.leftSide}>
        <h1 className={styles.title}>Скачать приложение</h1>
        <div className={styles.badgesBlock}>
          <ButtonLink
            href="https://apps.apple.com/kz/app/kcal-elyapp-%D1%81%D1%87%D0%B5%D1%82%D1%87%D0%B8%D0%BA-%D0%BA%D0%B0%D0%BB%D0%BE%D1%80%D0%B8%D0%B9/id6449166080"
            className={styles.imageButton}
          >
            <img src="/assets/images/app-store-badge.png" alt="kcal ELYapp AppStore" />
          </ButtonLink>
          <ButtonLink
            className={styles.imageButton}
            href="https://play.google.com/store/apps/details?id=com.elyapp.kcalc"
          >
            <img src="/assets/images/google-play-badge.png" alt="kcal ELYapp Google play" />
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};
