import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { ResponseCustom } from '../types';
import { SendMessagesRequest, SendMessagesResponse } from './types';
import { sendMessages } from './requests';

type UseSendMessages = () => {
  sendMessages: UseMutateAsyncFunction<
    ResponseCustom<SendMessagesResponse>,
    unknown,
    SendMessagesRequest
  >;
  loading: boolean;
  response?: SendMessagesResponse;
};

export const useSendMessages: UseSendMessages = () => {
  const token = localStorage.getItem('token') || '';

  const {
    mutateAsync,
    isPending,
    data: response,
  } = useMutation<
    ResponseCustom<SendMessagesResponse>,
    unknown,
    SendMessagesRequest
  >({
    mutationFn: (data) => sendMessages({ token, data }),
  });

  return {
    sendMessages: mutateAsync,
    loading: isPending,
    response: response?.data,
  };
};
