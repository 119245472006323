const ONE_DAY_MILLIS = 1000 * 60 * 60 * 24;

export const getYyyyMmDd = (timestamp: number): string => {
  const rawDate = new Date(timestamp);
  return new Intl.DateTimeFormat('ru').format(rawDate);
};

export const reverseDate = (date: string): string => {
  const splitted = date.split('-');
  return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
};

export const getTimestampFromDate = (date: string) => {
  const splitted = date.split('-');
  const year = Number(splitted[0]);
  const month = Number(splitted[1]) - 1;
  const day = Number(splitted[2]) + 1;
  const formattedDate = new Date(year, month, day);
  return formattedDate.getTime();
};

export const calcDaysCount = (timestamp: number): number => {
  const currentTimestamp = Date.now();
  return Math.trunc((currentTimestamp - timestamp) / ONE_DAY_MILLIS);
};

export const getCurrentDateHour = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  return `${year}-${month}-${day}|${hour}`;
};
