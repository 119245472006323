import { FC } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

interface Props {
  onClick: () => void;
  label: string;
  isActive: boolean;
}

export const TabButton: FC<Props> = ({ onClick, label, isActive }) => {
  return (
    <button
      className={cn(
        styles.tabButton,
        { [styles.active]: isActive },
        { ['bold']: isActive }
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
