import { SelectOptionsProps } from '../../../shared/Select/types';
import { Select } from '../../../shared/Select';
import { ChangeEvent, FC } from 'react';

interface Props {
  handler: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const ButtonType: FC<Props> = ({ handler }) => {
  const options: SelectOptionsProps[] = [
    {
      title: 'Brand',
      value: 'brand',
    },
    {
      title: 'White',
      value: 'white',
    },
  ];
  return (
    <>
      <h4>Button type</h4>
      <div>
        <Select options={options} onChange={handler} />
      </div>
    </>
  );
};
