import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { ResponseCustom } from '../types';
import { EntitlementRequest } from './types';
import { patchEntitlement } from './requests';

type UsePatchEntitlement = () => {
  patchEntitlement: UseMutateAsyncFunction<
    ResponseCustom<any>,
    unknown,
    EntitlementRequest
  >;
  loading: boolean;
};

export const usePatchEntitlement: UsePatchEntitlement = () => {
  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<any>,
    unknown,
    EntitlementRequest
  >({
    mutationFn: ({ documentId, entitlementId }) =>
      patchEntitlement({ documentId, entitlementId }),
  });

  return {
    patchEntitlement: mutateAsync,
    loading: isPending,
  };
};
