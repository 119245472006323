import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { searchUsers } from './requests';
import { ResponseCustom } from '../types';
import { UserSearchProps } from '../../common/types/users';
import { SearchRequest, SearchResponse } from '../../common/types/search';

type UseSearchElasticUsers = () => {
  searchElasticUsers: UseMutateFunction<
    ResponseCustom<SearchResponse<UserSearchProps>>,
    unknown,
    SearchRequest
  >;
  loading: boolean;
  data?: SearchResponse<UserSearchProps>;
};

export const useSearchElasticUsers: UseSearchElasticUsers = () => {
  const {
    mutate,
    data: response,
    isPending,
  } = useMutation<
    ResponseCustom<SearchResponse<UserSearchProps>>,
    unknown,
    SearchRequest
  >({
    mutationFn: ({ query, page }) => searchUsers({ query, page }),
  });

  return {
    searchElasticUsers: mutate,
    loading: isPending,
    data: response?.data,
  };
};
