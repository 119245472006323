import styles from './styles.module.scss';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  defaultValue: string | number;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSort: () => void;
}

export const InputOrder: FC<Props> = ({
  defaultValue,
  onInputChange,
  onSort,
}) => {
  return (
    <div className={styles.inputOrderWrapper}>
      <input
        className="input-order"
        type="tel"
        defaultValue={defaultValue}
        onChange={onInputChange}
      />
      <button type="button" className={styles.sortButton} onClick={onSort}>
        sort
      </button>
    </div>
  );
};
