import { FC, ReactElement } from 'react';
import { Loader } from '../../../common/shared/Loader';
import cn from 'classnames';
import styles from './styles.module.scss';

export interface CategoryProps<T> {
  title: T;
  _id?: string;
  label?: ReactElement;
  emoji?: string;
  secondEmoji?: string;
  order?: number;
}

interface CategoriesListProps<T> {
  clickHandler: (value: T) => void;
  activeCategory: string;
  isLoading: boolean;
  categories?: CategoryProps<T>[];
  className?: string;
}

export const CategoriesList: FC<CategoriesListProps<any>> = ({
  clickHandler,
  activeCategory,
  isLoading,
  categories,
  className,
}) => {
  const onClick = ({ title, _id }: { title: any; _id?: string }) => {
    if (_id) {
      clickHandler(_id);
    } else {
      clickHandler(title);
    }
  };
  return (
    <div className={cn(styles.wrapper, className)}>
      <Loader visible={isLoading} />
      {!isLoading && !!categories?.length && (
        <ul className={styles.list}>
          {categories?.map(
            ({ _id, title, label, emoji, secondEmoji }, index) => (
              <li
                key={index}
                className={cn({
                  [styles.active]:
                    title === activeCategory || _id === activeCategory,
                })}
                onClick={() => onClick({ title, _id })}
              >
                {label}
                {!!emoji && <span>{emoji}</span>}
                {!!secondEmoji && <span>{secondEmoji}</span>}
                <span>{title}</span>
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
};
