import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { deleteMenu } from './requests';
import { MongoResponse, ResponseCustom } from '../types';

type UseDeleteMenu = () => {
  deleteMenu: UseMutateAsyncFunction<
    ResponseCustom<MongoResponse>,
    unknown,
    string
  >;
  loading: boolean;
};

export const useDeleteMenu: UseDeleteMenu = () => {
  const token = localStorage.getItem('token') || '';

  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<MongoResponse>,
    unknown,
    string
  >({
    mutationFn: (_id) => deleteMenu({ _id, token }),
  });

  return {
    deleteMenu: mutateAsync,
    loading: isPending,
  };
};
