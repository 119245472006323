import { ChangeEvent, useState } from 'react';
import { Button } from '../../../common/shared/Button';
import { useAdminLogin } from '../../requests/login/useAdminLogin';
import cn from 'classnames';
import styles from './styles.module.scss';

export const LoginPage = () => {
  const [name, setName] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [isShowPass, setIsShowPass] = useState<boolean>(false);

  const onShowPasswordClick = () => {
    setIsShowPass((prev) => !prev);
  };

  const { adminLogin, errorMessage } = useAdminLogin();

  const onName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPass(event.target.value);
  };

  const onSend = async () => {
    if (name && pass) {
      await adminLogin({ name, pass });
    }
  };

  return (
    <section className="section">
      <div className={cn('container', styles.container)}>
        <div className={styles.titleWrapper}>
          {!errorMessage && <h1>Hello Elya!</h1>}
          {!!errorMessage && (
            <span className={styles.errorMessage}>{errorMessage}</span>
          )}
        </div>
        <input
          type="text"
          className={styles.input}
          placeholder="name"
          onChange={onName}
        />
        <div className={styles.inputWrapper}>
          <input
            type={isShowPass ? 'text' : 'password'}
            className={styles.input}
            placeholder="password"
            onChange={onPassword}
          />
          <button className={styles.asideButton} onClick={onShowPasswordClick}>
            {isShowPass ? 'hide' : 'show'}
          </button>
        </div>
        <Button handler={onSend}>
          <span>Login</span>
        </Button>
      </div>
    </section>
  );
};
