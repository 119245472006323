import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { cleanRecipesImages } from './requests';
import { ResponseCustom } from '../types';

type UseCleanRecipesImages = () => {
  cleanImages: UseMutateAsyncFunction<
    ResponseCustom<string> | void,
    unknown,
    void
  >;
  loading: boolean;
};

export const useCleanRecipesImages: UseCleanRecipesImages = () => {
  const token = localStorage.getItem('token') || '';

  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<string> | void,
    unknown,
    void
  >({
    mutationFn: () => cleanRecipesImages(token),
    onSuccess: (response) => {
      if (response?.error) {
        alert(response.error);
      }
    },
  });

  return {
    cleanImages: mutateAsync,
    loading: isPending,
  };
};
