import { SelectOptionsProps } from '../../../shared/Select/types';
import { Select } from '../../../shared/Select';
import { ChangeEvent, FC } from 'react';

interface Props {
  handler: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const AppRoute: FC<Props> = ({ handler }) => {
  const options: SelectOptionsProps[] = [
    {
      title: '',
      value: '',
    },
    {
      title: 'Account',
      value: 'AccountTab',
    },
    {
      title: 'Daily Ration',
      value: 'HomeTab',
    },
    {
      title: 'Products',
      value: 'ProductsTab',
    },
    {
      title: 'Water',
      value: 'WaterTab',
    },
    {
      title: 'Diet',
      value: 'DietTab',
    },
    {
      title: 'Settings',
      value: 'SettingsTab',
    },
  ];
  return (
    <>
      <h4>App screen</h4>
      <div>
        <Select options={options} onChange={handler} />
      </div>
    </>
  );
};
