import { createContext, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { LangTypes } from './common/types/common';
import router from './router';

const queryClient = new QueryClient();

export interface AppContextProps {
  lang: LangTypes;
  switchLang: (lang: LangTypes) => void;
}

export const AppContext = createContext<AppContextProps>({
  lang: 'en',
  switchLang: () => null,
});

function App() {
  const [lang, setLang] = useState<LangTypes>('en');

  const appContext: AppContextProps = {
    lang: lang,
    switchLang: (lang: LangTypes) => {
      setLang(lang);
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={appContext}>
        <RouterProvider router={router} />
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
