import { UserSearchProps } from '../../common/types/users';
import { FC } from 'react';
import styles from './styles.module.scss';
import { OwnProducts } from '../../entities/UserProfile/OwnProducts';
import { compareSnippetWithTextArray, getValue } from './utils/misc';
import { Promocode } from '../../entities/UserProfile/Promocode';
import { Entitlements } from '../../entities/UserProfile/Entitlements';
import {
  UserActivityLevel,
  UserAge,
  UserAva,
  UserCountry,
  UserCurrentWeight,
  UserCustomerId,
  UserEmail,
  UserFamilyName,
  UserFeedSystem,
  UserFirstInstallTime,
  UserGender,
  UserGoalTemp,
  UserHeight,
  UserLanguages,
  UserLastVisitedTime,
  UserName,
  UserOS,
  UserProfileName,
  UserTargetWeight,
  UserWaterLevel,
  UserWaterSystem,
} from '../../entities/UserProfile/components';

interface Props {
  usersList: UserSearchProps[];
  menuIds?: (string | null)[];
}

export const UsersElasticList: FC<Props> = ({ usersList, menuIds }) => {
  return (
    <ul className={styles.wrapper}>
      {usersList.map((user) => (
        <li key={user._meta.id} className={styles.item}>
          {!!user['profile.ava'] && (
            <UserAva
              src={user['profile.ava'].raw}
              alt={user['profile.name']?.raw || 'ava'}
            />
          )}
          {!!user.customerId && (
            <UserCustomerId customerId={getValue(user.customerId)} />
          )}
          {(!!user.firstInstallTime?.raw ||
            !!user['meta.firstInstallTime']?.raw) && (
            <UserFirstInstallTime
              timestamp={
                user.firstInstallTime?.raw ||
                user['meta.firstInstallTime']?.raw ||
                0
              }
            />
          )}
          {(!!user.firstInstallTime?.raw ||
            !!user['meta.firstInstallTime']?.raw) &&
            !!user['meta.lastVisited']?.raw && (
              <UserLastVisitedTime
                installTimestamp={
                  user.firstInstallTime?.raw ||
                  user['meta.firstInstallTime']?.raw ||
                  0
                }
                lastTimestamp={user['meta.lastVisited'].raw}
              />
            )}
          {!!user['meta.country'] && (
            <UserCountry country={getValue(user['meta.country'])} />
          )}
          {!!user['meta.languages']?.raw && (
            <UserLanguages languages={user['meta.languages'].raw} />
          )}
          {!!user['userPersonalData.givenName'] && (
            <UserName name={getValue(user['userPersonalData.givenName'])} />
          )}
          {!!user['userPersonalData.familyName'] && (
            <UserFamilyName
              familyName={getValue(user['userPersonalData.familyName'])}
            />
          )}
          {!!user['userPersonalData.email'] && (
            <UserEmail email={getValue(user['userPersonalData.email'])} />
          )}
          {!!user['userPersonalData.OS'] && (
            <UserOS os={getValue(user['userPersonalData.OS'])} />
          )}
          {!!user['profile.name'] && (
            <UserProfileName profileName={getValue(user['profile.name'])} />
          )}
          {!!user['profile.gender'] && (
            <UserGender gender={getValue(user['profile.gender'])} />
          )}
          {!!user['profile.age'] && (
            <UserAge age={getValue(user['profile.age'])} />
          )}
          {!!user['profile.height'] && (
            <UserHeight height={getValue(user['profile.height'])} />
          )}
          {!!user['profile.weightCurrent'] && (
            <UserCurrentWeight
              weight={getValue(user['profile.weightCurrent'])}
            />
          )}
          {!!user['profile.weightTarget'] && (
            <UserTargetWeight weight={getValue(user['profile.weightTarget'])} />
          )}
          {!!user['profile.changeWeightTemp'] &&
            !!user['profile.weightCurrent'] &&
            !!user['profile.weightTarget'] && (
              <UserGoalTemp
                temp={user['profile.changeWeightTemp'].raw}
                weightCurrent={user['profile.weightCurrent'].raw}
                weightTarget={user['profile.weightTarget'].raw}
              />
            )}
          {!!user['profile.activityLevel'] && (
            <UserActivityLevel
              activityLevel={user['profile.activityLevel'].raw}
            />
          )}
          {!!user['profile.feedSystem'] && (
            <UserFeedSystem feedSystem={user['profile.feedSystem'].raw} />
          )}
          {!!user['profile.waterSystem'] && (
            <UserWaterSystem waterSystem={user['profile.waterSystem'].raw} />
          )}
          {!!user['profile.waterLevel'] && (
            <UserWaterLevel waterLevel={user['profile.waterLevel'].raw} />
          )}
          {!!user['ownProducts.name'] && (
            <OwnProducts
              data={compareSnippetWithTextArray({
                text: user['ownProducts.name'].raw,
                snippet: user['ownProducts.name'].snippet,
              })}
            />
          )}
          <Promocode
            documentId={user.id.raw}
            endDate={user['promoCode.endDate']?.raw}
          />
          <Entitlements
            documentId={user.id.raw}
            menuIds={menuIds}
            acquiredMenus={user.entitlements}
          />
        </li>
      ))}
    </ul>
  );
};
