import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { patchMenu } from './requests';
import { ResponseCustom } from '../types';
import { PatchMenuRequest } from './types';
import { MenusProps } from '../../common/types/menu';

type UsePatchMenu = ({ successCallback }: { successCallback: () => void }) => {
  patchMenu: UseMutateAsyncFunction<
    ResponseCustom<MenusProps>,
    unknown,
    Omit<PatchMenuRequest, 'token'>
  >;
  loading: boolean;
};

export const usePatchMenu: UsePatchMenu = ({ successCallback }) => {
  const token = localStorage.getItem('token') || '';

  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<MenusProps>,
    unknown,
    Omit<PatchMenuRequest, 'token'>
  >({
    mutationFn: ({ _id, data }) => patchMenu({ _id, token, data }),
    onSuccess: (response) => {
      if (response.data) {
        successCallback();
      }
    },
  });

  return {
    patchMenu: mutateAsync,
    loading: isPending,
  };
};
