import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { fetchMenus } from './requests';
import { ResponseCustom } from '../types';
import { MenusProps } from '../../common/types/menu';
import { LONG_STALE_TIME, QUERY_KEYS } from '../constants';

type UseFetchMenus = () => {
  loading: boolean;
  refetch: () => Promise<QueryObserverResult<ResponseCustom<MenusProps[]>>>;
  menusData?: MenusProps[];
};

export const useFetchMenus: UseFetchMenus = () => {
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.FETCH_MENUS],
    queryFn: fetchMenus,
    refetchOnWindowFocus: false,
    staleTime: LONG_STALE_TIME,
  });

  return {
    loading: isLoading,
    refetch,
    menusData: response?.data,
  };
};
