import { API, HEADERS_JSON } from '../constants';
import { SendMessagesRequest, SendMessagesResponse } from './types';
import { ResponseCustom } from '../types';

export const fetchPickedTokens = async ({
  token,
  customerIds,
}: {
  token: string;
  customerIds: string[];
}) => {
  const response = await fetch(`${API}/admin/messages/picked-tokens`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
    body: JSON.stringify({ customerIds }),
  });

  if (!response.ok) {
    alert('Server error');
  }

  return await response.json();
};

export const sendMessages = async ({
  token,
  data,
}: {
  token: string;
  data: SendMessagesRequest;
}): Promise<ResponseCustom<SendMessagesResponse>> => {
  const response = await fetch(`${API}/admin/messages/send`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...HEADERS_JSON,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    alert('Server error');
  }

  return await response.json();
};
