import React, { FC } from 'react';
import cn from 'classnames';
import { NutrientsCell } from './NutrientsCell';
import { Total } from './Total';
import { useTotalMenu } from '../hooks/useTotalMenu';
import { calcTotalDayNutrients } from '../utils';
import {
  AccessTypes,
  DisplayProduct,
  MenuSet,
  MenusProps,
} from '../../../common/types/menu';
import {
  IMAGE_ENDPOINT,
  MENU_IMG_PATH,
  TENGE_LABEL,
} from '../../../common/constants/common';
import styles from './styles.module.scss';
import { RoundLabelAllow, RoundLabelPending } from '../../../shared/RoundLabel';

interface Props {
  menuSet: MenuSet[];
  menuData: MenusProps;
}

const DisplayMenu: FC<Props> = ({ menuSet, menuData }) => {
  const totalMenu = useTotalMenu({ menuSet });
  const imageSrc = `${IMAGE_ENDPOINT}/${MENU_IMG_PATH}/${menuData.imageName}`;

  return (
    <>
      <div className={styles.rowBlockTop}>
        <div className={styles.imageWrapper}>
          <img src={imageSrc} alt={menuData.name} />
        </div>
        <div className={styles.listBlock}>
          <div className={styles.plainRow}>
            <div className={styles.icon}>
              <div className={styles.roundBullet}>
                <div>{menuData.order}</div>
              </div>
            </div>
            <span>
              <b>order</b>
            </span>
          </div>
          <div className={styles.plainRow}>
            <div className={styles.icon}>
              {menuData.publicAccess ? RoundLabelAllow : RoundLabelPending}
            </div>
            <span>{menuData.publicAccess ? 'Public' : 'Private'}</span>
          </div>
          <div className={styles.plainRow}>
            <div className={styles.icon}>
              <span>
                {menuData.accessType === AccessTypes.paid ? '💰' : '🛟'}
              </span>
            </div>
            <span>{menuData.accessType}</span>
          </div>
          <div className={cn(styles.plainRow, styles.priceBlock)}>
            <div className={styles.icon}>
              <img
                src="/assets/images/kaspikz-logo-small-red.png"
                alt="kaspi-logo"
              />
            </div>
            <span>Kaspi price: </span>
            <b>
              {menuData.kaspiPrice} {TENGE_LABEL}
            </b>
          </div>
          <div className={styles.plainRow}>{}</div>
          {menuData.subscriptionGift && (
            <div className={styles.plainRow}>
              <div className={styles.icon}>🎀</div>
              <span>subscription gift</span>
            </div>
          )}
          {menuData.accessType === AccessTypes.paid && (
            <div className={styles.plainRow}>
              <span className={cn('bold', styles.downsizeText)}>
                purchase ID:
              </span>
              <span>{menuData.purchaseId}</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.block}>
        <dl className={cn('grid-2-Columns-1-6', styles.plainRow)}>
          <dt>short name:</dt>
          <dd className="bold">{menuData.shortName ?? ''}</dd>
        </dl>
        <dl className={cn('grid-2-Columns-1-6', styles.plainRow)}>
          <dt>full name:</dt>
          <dd className="bold">{menuData.name}</dd>
        </dl>
        <dl className={cn('grid-2-Columns-1-6', styles.plainRow)}>
          <dt>weight profit:</dt>
          <dd className="bold">{menuData.weightProfit ?? ''}</dd>
        </dl>
        <dl className={cn('grid-2-Columns-1-6', styles.plainRow)}>
          <dt>attached menu:</dt>
          <dd className="bold">{menuData.attachedMenuId ?? ''}</dd>
        </dl>
        <p>{menuData.description}</p>
      </div>
      <div className={styles.block}>
        {menuSet.map(({ day, products }) => {
          const totalDay = calcTotalDayNutrients(products as DisplayProduct[]);
          if (day) {
            return (
              <div key={day.dayId}>
                <div>
                  <h4>
                    <span>Day: {day.value}</span>&nbsp;
                    {menuData.accessType === AccessTypes.paid && (
                      <span>{day.isPaid ? '💰' : '🛟'}</span>
                    )}
                  </h4>
                  <div className={styles.listWrapper}>
                    {!!products?.length &&
                      products.map((product, index) => {
                        return (
                          <div
                            key={product.internalId}
                            className={cn(styles.listItem, {
                              [styles.odd]: (index + 1) % 2,
                            })}
                          >
                            <span className={styles.productId}>
                              id: {product._id}
                            </span>
                            <div className={styles.itemRow}>
                              <div className={styles.listItem_textBlock}>
                                <span className={styles.title}>
                                  {product.name}
                                </span>
                                <p>{product.productDescription}</p>
                                {!!product.mealtimes?.length && (
                                  <ul className={styles.plainRow}>
                                    {product.mealtimes.map((item) => (
                                      <li
                                        key={item.time}
                                        className={cn(
                                          'tag',
                                          `tag_${item.time}`
                                        )}
                                      >
                                        {item.time}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                              <div className={styles.rightSide}>
                                <div className={styles.cell}>
                                  <span className={styles.title}>weight</span>
                                  <span className={styles.value}>
                                    {product.weight}
                                  </span>
                                </div>
                                <NutrientsCell
                                  proteins={
                                    product.calculatedNutrients.proteins
                                  }
                                  fat={product.calculatedNutrients.fat}
                                  carbohydrates={
                                    product.calculatedNutrients.carbohydrates
                                  }
                                  kcal={product.calculatedNutrients.kcal}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <Total
                  title="Day total"
                  weight={totalDay.weight}
                  proteins={totalDay.proteins}
                  fat={totalDay.fat}
                  carbohydrates={totalDay.carbohydrates}
                  kcal={totalDay.kcal}
                />
              </div>
            );
          }
        })}
        <Total
          title="Total"
          weight={totalMenu.weight}
          proteins={totalMenu.proteins}
          fat={totalMenu.fat}
          carbohydrates={totalMenu.carbohydrates}
          kcal={totalMenu.kcal}
          className={styles.total}
        />
      </div>
    </>
  );
};

export default DisplayMenu;
