import cn from 'classnames';
import styles from './styles.module.scss';

export const Contacts = () => {
  return (
    <section className="section">
      <div className={cn('container', styles.container)}>
        <h3>Contact Us</h3>
        <a className="link" href="mailto:elyabaiduan@gmail.com">
          elyabaiduan@gmail.com
        </a>
      </div>
    </section>
  );
};
