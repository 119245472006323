import {
  AccessTypes,
  FilteredMenus,
  MenuSet,
  MenusProps,
} from '../../../common/types/menu';
import { nanoid } from '../../../../common/utils/idGenerator';
import { usePostMenu } from '../../../requests/menu/usePostMenu';
import { usePatchMenu } from '../../../requests/menu/usePatchMenu';
import { QueryObserverResult } from '@tanstack/react-query';
import { ResponseCustom } from '../../../requests/types';
import { MutableRefObject } from 'react';
import { MenusOriginData } from '../../../pages/Menu';

interface SaveMenuProps {
  onClose: () => void;
  refetchMenus: () => Promise<
    QueryObserverResult<ResponseCustom<MenusProps[]>>
  >;
  menuOrder: MutableRefObject<number>;
  accessType: AccessTypes;
  menuName: MutableRefObject<string>;
  menuShortName: MutableRefObject<string>;
  weightProfit: MutableRefObject<string>;
  attachedMenuId: MutableRefObject<string>;
  isValidMenu: boolean;
  menuSet: MenuSet[];
  isPublic: boolean;
  subscriptionGift: boolean;
  imageName: string;
  menuDescription: MutableRefObject<string>;
  purchaseId: MutableRefObject<string | null>;
  kaspiPrice: MutableRefObject<number | null>;
  originPurchaseId?: string | null;
  menuId?: string;
  menusOriginData?: MenusOriginData[];
}

type UseSaveMenu = ({
  onClose,
  refetchMenus,
  menuOrder,
  accessType,
  menuName,
  menuShortName,
  weightProfit,
  attachedMenuId,
  menuSet,
  isPublic,
  subscriptionGift,
  imageName,
  menuDescription,
  purchaseId,
  kaspiPrice,
  originPurchaseId,
  menuId,
  menusOriginData,
  isValidMenu,
}: SaveMenuProps) => {
  saveMenu: () => Promise<void>;
  loading: boolean;
};

export const useSaveMenu: UseSaveMenu = ({
  onClose,
  refetchMenus,
  menuOrder,
  accessType,
  menuName,
  menuShortName,
  weightProfit,
  attachedMenuId,
  menuSet,
  isPublic,
  subscriptionGift,
  imageName,
  menuDescription,
  purchaseId,
  kaspiPrice,
  originPurchaseId,
  menuId,
  menusOriginData,
  isValidMenu,
}) => {
  const successPostCallback = async () => {
    await refetchMenus();
    onClose();
  };

  const { postMenu, loading: createMenuLoading } = usePostMenu({
    successCallback: successPostCallback,
  });

  const { patchMenu, loading: updateMenuLoading } = usePatchMenu({
    successCallback: successPostCallback,
  });

  const isEditMode = !!menuId;

  const saveMenu = async () => {
    const isPaidMenu = accessType === AccessTypes.paid;
    const isValidName = !!menuName.current;

    if (!isValidName) {
      alert('Invalid menu name');
      return;
    }

    if (isValidMenu) {
      const filteredMenu = menuSet.filter(
        ({ day, products }) => !!day && products?.length
      ) as FilteredMenus[];

      const menu = filteredMenu
        .map(({ day, products }) => ({
          day,
          products: products
            .map(
              ({
                _id,
                internalId,
                productType,
                weight,
                mealtimes,
                productDescription,
                order,
              }) => {
                const mealtimesSorted = mealtimes?.sort(
                  (a, b) => a.order - b.order
                );
                return {
                  internalId: internalId ?? nanoid(),
                  productId: _id,
                  productType,
                  weight,
                  mealtimes: mealtimesSorted,
                  productDescription,
                  order,
                };
              }
            )
            .sort((productA, productB) => productA.order - productB.order),
        }))
        .sort((menuA, menuB) => menuA.day.value - menuB.day.value);

      const sameName = menusOriginData?.find(
        (item) => item.menuName === menuName.current
      );

      const samePurchaseId = menusOriginData?.find(
        (item) =>
          item.purchaseId !== null &&
          item.purchaseId === purchaseId.current &&
          originPurchaseId !== purchaseId.current
      );

      if (samePurchaseId && isPaidMenu) {
        alert('Такой ID покупки уже существует (purchaseId)');
        return;
      }

      if (sameName && !isEditMode) {
        alert('Такое имя уже существует');
        return;
      }

      if (isPaidMenu && !purchaseId.current) {
        alert('Нужно заполнить поле "purchaseId"\nиденитфикатор покупки');
        return;
      }

      if (isEditMode) {
        await patchMenu({
          _id: menuId,
          data: {
            order: menuOrder.current,
            publicAccess: isPublic,
            subscriptionGift,
            accessType,
            purchaseId: purchaseId.current,
            imageName,
            kaspiPrice: kaspiPrice.current,
            name: menuName.current,
            shortName: menuShortName.current,
            description: menuDescription.current,
            weightProfit: weightProfit.current,
            attachedMenuId: attachedMenuId.current,
            menu,
          },
        });
      } else {
        await postMenu({
          data: {
            order: menuOrder.current,
            publicAccess: isPublic,
            subscriptionGift,
            accessType,
            purchaseId: purchaseId.current,
            imageName,
            kaspiPrice: kaspiPrice.current,
            name: menuName.current,
            shortName: menuShortName.current,
            description: menuDescription.current,
            weightProfit: weightProfit.current,
            attachedMenuId: attachedMenuId.current,
            menu,
          },
        });
      }
    } else {
      alert(`Invalid menu list`);
    }
  };

  return {
    saveMenu,
    loading: createMenuLoading || updateMenuLoading,
  };
};
