import { useQuery } from '@tanstack/react-query';
import { fetchProductsByCategory } from './requests';
import { ProductsPropsWithDbId } from '../../common/types/products';
import { LONG_STALE_TIME, QUERY_KEYS } from '../constants';

type UseFetchProductsByCategory = (category: string) => {
  loading: boolean;
  productsList?: ProductsPropsWithDbId[];
  refetch: () => void;
};

export const useFetchProductsByCategory: UseFetchProductsByCategory = (
  category
) => {
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.PRODUCTS_BY_CATEGORIES, category],
    queryFn: () => fetchProductsByCategory(category),
    refetchOnWindowFocus: false,
    staleTime: LONG_STALE_TIME,
  });

  return {
    loading: isLoading,
    productsList: response?.data,
    refetch,
  };
};
