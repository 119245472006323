import {
  ActivityLevel,
  ChangeWeightTemp,
  FeedSystem,
  WaterLevelSystem,
} from '../../common/types/users';

export const getActivityLevel = (value: ActivityLevel) => {
  switch (value) {
    case ActivityLevel.Non:
      return 'Базовый (полный покой)';
    case ActivityLevel.Average:
      return 'Низкий (сидячий образ жизни)';
    case ActivityLevel.Low:
      return 'Малый (тренировки 1-3 раза в неделю)';
    case ActivityLevel.Middle:
      return 'Средний (тренировки 3-5 раз в неделю)';
    case ActivityLevel.High:
      return 'Высокий (тренировки 5-7 раз в неделю)';
    case ActivityLevel.ExtraHigh:
      return 'Очень высокий (тяжелый физический труд)';
  }
};

export const getFeedSystem = (value: FeedSystem) => {
  switch (value) {
    case FeedSystem.Balanced:
      return 'Сбалансированная (30/20/50)';
    case FeedSystem.LowProtein:
      return 'Низкобелковая (15/20/65)';
    case FeedSystem.LowFat:
      return 'Низкожировая (40/15/45)';
    case FeedSystem.LowCarbs:
      return 'Низкоуглеводная (65/20/15)';
  }
};

type GetChangeWeightTemp = ({
  value,
  currentWeight,
  targetWeight,
}: {
  value: ChangeWeightTemp;
  currentWeight: string;
  targetWeight: string;
}) => string;

export const getChangeWeightTemp: GetChangeWeightTemp = ({
  value,
  currentWeight,
  targetWeight,
}) => {
  const currentWeightNumber = parseFloat(currentWeight);
  const targetWeightNumber = parseFloat(targetWeight);
  const lossWeight =
    currentWeightNumber > targetWeightNumber &&
    currentWeightNumber !== targetWeightNumber;
  if (currentWeightNumber === targetWeightNumber) {
    return 'Поддержание текущего веса';
  } else {
    switch (value) {
      case ChangeWeightTemp.low:
        return lossWeight ? 'Медленное снижение веса' : 'Медленный набор веса';
      case ChangeWeightTemp.moderate:
        return lossWeight ? 'Умеренное снижение веса' : 'Умеренный набор веса';
      case ChangeWeightTemp.fast:
        return lossWeight ? 'Быстрое снижение веса' : 'Быстрый набор веса';
      case ChangeWeightTemp.extraFast:
        return lossWeight
          ? 'Экстремально быстрое снижение веса'
          : 'Экстремально быстрый набор веса';
    }
  }
  return '';
};

export const getWaterSystem = (value: WaterLevelSystem) => {
  if (value === WaterLevelSystem.custom) {
    return 'Свой расчет';
  } else {
    return 'Системный расчет';
  }
};
