import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { MenuSet } from '../../../common/types/menu';
import { findProduct } from '../utils';

type OnChangeInputDay = ({
  dayId,
}: {
  dayId: string;
}) => (event: ChangeEvent<HTMLInputElement>) => void;

type OnChangeInputProduct = ({
  dayId,
  internalId,
}: {
  dayId: string;
  internalId: string;
}) => (event: ChangeEvent<HTMLInputElement>) => void;

type UseSort = ({
  menuSet,
}: {
  menuSet: MenuSet[];
  setMenuSet: Dispatch<SetStateAction<MenuSet[]>>;
}) => {
  onChangeInputDay: OnChangeInputDay;
  onSortDays: () => void;
  onChangeInputProduct: OnChangeInputProduct;
  onSortProducts: () => void;
};

export const useSort: UseSort = ({ menuSet, setMenuSet }) => {
  const onChangeInputDay: OnChangeInputDay =
    ({ dayId }) =>
    (event) => {
      const value = Number(event.target.value);
      const targetDay = menuSet.find((item) => item.day?.dayId === dayId);

      if (targetDay?.day) {
        targetDay.day.value = value;
      }
    };

  const onSortDays = () => {
    menuSet.sort((a, b) => (a.day?.value || 0) - (b.day?.value || 0));
    setMenuSet([...menuSet]);
  };

  const onChangeInputProduct: OnChangeInputProduct =
    ({ dayId, internalId }) =>
    (event) => {
      const value = Number(event.target.value);
      const product = findProduct({ menuSet, dayId, internalId });

      if (product) {
        product.order = value;
      }
    };

  const onSortProducts = () => {
    menuSet.forEach((item) =>
      item.products?.sort((a, b) => (a?.order || 0) - (b?.order || 0))
    );
    setMenuSet([...menuSet]);
  };

  return {
    onChangeInputDay,
    onSortDays,
    onChangeInputProduct,
    onSortProducts,
  };
};
