import cn from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export const RoundLabelAllow = (
  <span className={cn(styles.roundLabel, styles.allow)} />
);
export const RoundLabelPending = (
  <span className={cn(styles.roundLabel, styles.pending)} />
);
