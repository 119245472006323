import { FC, ReactElement } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props {
  href: string;
  children: ReactElement;
  className?: string;
}

export const ButtonLink: FC<Props> = ({ href, children, className }) => {
  return (
    <button className={cn(styles.buttonLink, className)}>
      <a href={href} />
      {children}
    </button>
  );
};
