import styles from './styles.module.scss';
import { calcDaysCount, getYyyyMmDd } from '../../common/utils/date';
import {
  ActivityLevel,
  ChangeWeightTemp,
  FeedSystem,
  WaterLevelSystem,
} from '../../common/types/users';
import {
  getActivityLevel,
  getChangeWeightTemp,
  getFeedSystem,
  getWaterSystem,
} from '../../components/ProductsSearch/content';
import cn from 'classnames';

export const UserAva = ({ src, alt }: { src: string; alt: string }) => (
  <div>
    <img className={styles.avaImage} src={src} alt={alt} />
  </div>
);

export const UserCustomerId = ({ customerId }: { customerId: string }) => (
  <dl className={styles.row}>
    <dt>Customer ID</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: customerId,
      }}
    />
  </dl>
);

export const UserFirstInstallTime = ({ timestamp }: { timestamp: number }) => (
  <dl className={styles.row}>
    <dt>Создано</dt>
    <dd>{getYyyyMmDd(timestamp)}</dd>
  </dl>
);

export const UserLastVisitedTime = ({
  installTimestamp,
  lastTimestamp,
}: {
  installTimestamp: number;
  lastTimestamp: number;
}) => {
  const daysDiff = calcDaysCount(lastTimestamp);

  const installDate = getYyyyMmDd(installTimestamp);
  const lastVisitedDate = getYyyyMmDd(lastTimestamp);
  const isSameDay = installDate === lastVisitedDate;

  const success = daysDiff <= 7 && !isSameDay;
  const warn = daysDiff > 7 && daysDiff <= 29 && !isSameDay;
  const alert = daysDiff > 29 && !isSameDay;

  return (
    <dl className={styles.row}>
      <dt>Последнее посещение</dt>
      <dd>
        <span
          className={cn(
            'bold',
            { [styles.success]: success },
            { [styles.warn]: warn },
            { [styles.alert]: alert }
          )}
        >
          {getYyyyMmDd(lastTimestamp)}
        </span>
      </dd>
    </dl>
  );
};

export const UserCountry = ({ country }: { country: string }) => (
  <dl className={styles.row}>
    <dt>Страна</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: country,
      }}
    />
  </dl>
);

export const UserLanguages = ({ languages }: { languages: string[] }) => {
  return (
    <dl className={styles.row}>
      <dt>Языки</dt>
      <dd>{languages.join(', ')}</dd>
    </dl>
  );
};

export const UserName = ({ name }: { name: string }) => (
  <dl className={styles.row}>
    <dt>Имя</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: name,
      }}
    />
  </dl>
);

export const UserFamilyName = ({ familyName }: { familyName: string }) => (
  <dl className={styles.row}>
    <dt>Фамилия</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: familyName,
      }}
    />
  </dl>
);

export const UserEmail = ({ email }: { email: string }) => (
  <dl className={styles.row}>
    <dt>email</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: email,
      }}
    />
  </dl>
);

export const UserOS = ({ os }: { os: string }) => (
  <dl className={styles.row}>
    <dt>OS</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: os,
      }}
    />
  </dl>
);

export const UserProfileName = ({ profileName }: { profileName: string }) => (
  <dl className={styles.row}>
    <dt>Имя в приложении</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: profileName,
      }}
    />
  </dl>
);

export const UserGender = ({ gender }: { gender: string }) => (
  <dl className={styles.row}>
    <dt>Пол</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: gender,
      }}
    />
  </dl>
);

export const UserAge = ({ age }: { age: string }) => (
  <dl className={styles.row}>
    <dt>Возраст</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: age,
      }}
    />
  </dl>
);

export const UserHeight = ({ height }: { height: string }) => (
  <dl className={styles.row}>
    <dt>Рост</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: height,
      }}
    />
  </dl>
);

export const UserCurrentWeight = ({ weight }: { weight: string }) => (
  <dl className={styles.row}>
    <dt>Текущий вес</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: weight,
      }}
    />
  </dl>
);

export const UserTargetWeight = ({ weight }: { weight: string }) => (
  <dl className={styles.row}>
    <dt>Желаемый вес</dt>
    <dd
      dangerouslySetInnerHTML={{
        __html: weight,
      }}
    />
  </dl>
);

export const UserGoalTemp = ({
  temp,
  weightCurrent,
  weightTarget,
}: {
  temp: ChangeWeightTemp;
  weightCurrent: string;
  weightTarget: string;
}) => (
  <dl className={styles.row}>
    <dt>Изменение веса</dt>
    <dd>
      {getChangeWeightTemp({
        value: temp,
        currentWeight: weightCurrent,
        targetWeight: weightTarget,
      })}
    </dd>
  </dl>
);

export const UserActivityLevel = ({
  activityLevel,
}: {
  activityLevel: ActivityLevel;
}) => (
  <dl className={styles.row}>
    <dt>Уровень активности</dt>
    <dd>{getActivityLevel(activityLevel)}</dd>
  </dl>
);

export const UserFeedSystem = ({ feedSystem }: { feedSystem: FeedSystem }) => (
  <dl className={styles.row}>
    <dt>Система питания</dt>
    <dd>{getFeedSystem(feedSystem)}</dd>
  </dl>
);

export const UserWaterSystem = ({
  waterSystem,
}: {
  waterSystem: WaterLevelSystem;
}) => (
  <dl className={styles.row}>
    <dt>Расчет нормы воды в день</dt>
    <dd>{getWaterSystem(waterSystem)}</dd>
  </dl>
);

export const UserWaterLevel = ({ waterLevel }: { waterLevel: string }) => (
  <dl className={styles.row}>
    <dt>Норма воды в день</dt>
    <dd>{waterLevel}</dd>
  </dl>
);
