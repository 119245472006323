import cn from 'classnames';
import { Banner } from '../../components/Banner';
import styles from './styles.module.scss';

export const HomeKcal = () => {
  return (
    <section className="section">
      <div className={cn('container', styles.container)}>
        <Banner />
      </div>
    </section>
  );
};
