import { useContext } from 'react';
import { LangSwitcher } from '../../../common/shared/LangSwitcher';
import { AppContext } from '../../../App';
import { textEn } from './textEn';
import { textRu } from './textRu';
import cn from 'classnames';
import styles from '../../../common/pages/PrivacyPolicy/styles.module.scss';
import localStyles from './styles.module.scss';

export const SubscriptionAgreement = () => {
  const { lang } = useContext(AppContext);
  return (
    <section className="section">
      <div className={cn('container', styles.container)}>
        <div className={localStyles.langSwitcherWrapper}>
          <LangSwitcher />
        </div>
        {lang === 'en' ? textEn : textRu}
      </div>
    </section>
  );
};
