import { getTimestampFromSubscriptionDate } from './misc';

export const validateSubscriptionDate = (date: string) => {
  const subscriptionTimestamp = getTimestampFromSubscriptionDate(date);

  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();

  return subscriptionTimestamp > currentTimestamp;
};
