import { FC } from 'react';
import { ImageType } from '../../../requests/app/types';
import { UploadImage } from '../../../components/UploadImage';
import { API_IMAGE } from '../../../requests/constants';
import cn from 'classnames';
import styles from './styles.module.scss';
import {
  MESSAGE_BANNER_BACKGROUND_IMG_PATH,
  MESSAGE_BANNER_MAIN_IMG_PATH,
} from '../../../common/constants/common';

interface Props {
  handler: (imageUrl: string) => void;
  imageUrl: string | null;
  isBackgroundImage?: boolean;
}

export const BannerImage: FC<Props> = ({
  handler,
  imageUrl,
  isBackgroundImage = true,
}) => {
  const src = isBackgroundImage
    ? `${API_IMAGE}/${MESSAGE_BANNER_BACKGROUND_IMG_PATH}/${imageUrl}`
    : `${API_IMAGE}/${MESSAGE_BANNER_MAIN_IMG_PATH}/${imageUrl}`;

  return (
    <div>
      <h4>
        {isBackgroundImage && 'Banner background image'}
        {!isBackgroundImage && 'Banner image'}
      </h4>
      {!!imageUrl && (
        <div
          className={cn(
            styles.imageFrame,
            { [styles.background]: isBackgroundImage },
            { [styles.main]: !isBackgroundImage }
          )}
        >
          <img src={src} alt={imageUrl} />
        </div>
      )}
      <UploadImage
        imageType={
          isBackgroundImage
            ? ImageType.messageBannerBackground
            : ImageType.messageBannerImage
        }
        setImageName={handler}
      />
    </div>
  );
};
