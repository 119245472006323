import { Outlet } from 'react-router-dom';
import { HeaderErates } from '../Header';

export const LayoutErates = () => {
  return (
    <>
      <HeaderErates />
      <main>
        <Outlet />
      </main>
    </>
  );
};
