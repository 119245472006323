import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { LayoutKcal } from '../kCal/components/Layout';
import { ErrorPage } from '../common/pages/ErrorPage';
import { HomeKcal } from '../kCal/pages/Home';
import { Contacts } from '../common/pages/Contacts';
import { LoginPage } from '../Admin/pages/Login';
import { Layout } from '../Admin/components/Layout';
import { ProductsListPage } from '../Admin/pages/ProductsList';
import { RecipesPage } from '../Admin/pages/Recipes';
import { UserProductsPage } from '../Admin/pages/UserProducts';
import { ProductsSearchPage } from '../Admin/pages/ProductsSearch';
import { SubscriptionAgreement } from '../eRates/pages/SubscriptionAgreement';
import { LayoutErates } from '../eRates/components/Layout';
import { HomeErates } from '../eRates/pages/Home';
import { PrivacyPolicyKcal } from '../kCal/pages/PrivacyPolicy';
import { PrivacyPolicyErates } from '../eRates/pages/PrivacyPolicy';
import { MenuPage } from '../Admin/pages/Menu';
import { adminLoader } from './adminLoader';
import { UsersPage } from '../Admin/pages/Users';
import { MessagesPage } from '../Admin/pages/Messages';
import { Dashboard } from '../Admin/pages/Dashboard';

export default createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<LayoutKcal />} errorElement={<ErrorPage />}>
        <Route path="/kcal" element={<HomeKcal />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyKcal />} />
        <Route path="/contacts" element={<Contacts />} />
      </Route>
      <Route path="/admin-login" element={<LoginPage />} />
      <Route
        element={<Layout />}
        loader={adminLoader}
        errorElement={<ErrorPage />}
      >
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/admin-messages" element={<MessagesPage />} />
        <Route path="/admin-users" element={<UsersPage />} />
        <Route path="/admin-products-search" element={<ProductsSearchPage />} />
        <Route path="/admin-products-list" element={<ProductsListPage />} />
        <Route path="/admin-recipes" element={<RecipesPage />} />
        <Route path="/admin-menu" element={<MenuPage />} />
        <Route path="/admin-user-products" element={<UserProductsPage />} />
      </Route>
      <Route element={<LayoutErates />} errorElement={<ErrorPage />}>
        <Route path="/erates" element={<HomeErates />} />
        <Route
          path="/privacy-policy-erates"
          element={<PrivacyPolicyErates />}
        />
        <Route path="/contacts-erates" element={<Contacts />} />
        <Route
          path="/subscription-agreement-erates"
          element={<SubscriptionAgreement />}
        />
      </Route>
    </>
  )
);
