import cn from 'classnames';
import styles from './styles.module.scss';
import { ClipboardIcon } from '../../common/icons/ClipboardIcon';
import { BLACK_COLOR } from '../../common/constants/common';
import { FC } from 'react';
import { SearchProductsResults } from '../../requests/searchProducts/types';
import { SearchResponse } from '../../common/types/search';

interface Props {
  data: SearchResponse<SearchProductsResults>;
}

export const ProductsSearch: FC<Props> = ({ data }) => {
  const onBufferCopy = (event: any, value: string) => {
    navigator.clipboard.writeText(value);
    const target = event.target.closest('.cell-id').querySelector('.item-id');
    target.style.color = '#5b8fd3';
  };

  return (
    <div className={styles.resultBlock}>
      <ul className={cn(styles.columnsGrid, styles.tableHeader)}>
        <li>category</li>
        <li>ID</li>
        <li>name</li>
        <li>prot</li>
        <li>fat</li>
        <li>carb</li>
        <li>kcal</li>
      </ul>
      <ul className={styles.listWrapper}>
        {!!data?.results &&
          data.results.map((item) => (
            <li
              key={item.id.raw}
              className={cn(styles.columnsGrid, styles.item)}
            >
              <span>{item.category.raw}</span>
              <div className={cn(styles.cellId, 'cell-id')}>
                <span className="item-id">{item.id.raw}</span>
                <button
                  className={styles.copyButton}
                  onClick={(event) => onBufferCopy(event, item.id.raw)}
                >
                  <ClipboardIcon color={BLACK_COLOR} />
                </button>
              </div>
              <span
                className={styles.cellName}
                dangerouslySetInnerHTML={{ __html: item.name.snippet }}
              />
              <span>{item.proteins.raw}</span>
              <span>{item.fat.raw}</span>
              <span>{item.carbohydrates.raw}</span>
              <span>{item.kcal.raw}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};
