import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { ResponseCustom } from '../types';
import { deleteEntitlement } from './requests';
import { EntitlementRequest } from './types';

type UseDeleteEntitlement = () => {
  deleteEntitlement: UseMutateAsyncFunction<
    ResponseCustom<any>,
    unknown,
    EntitlementRequest
  >;
  loading: boolean;
};

export const useDeleteEntitlement: UseDeleteEntitlement = () => {
  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<any>,
    unknown,
    EntitlementRequest
  >({
    mutationFn: ({ documentId, entitlementId }) =>
      deleteEntitlement({ documentId, entitlementId }),
  });

  return {
    deleteEntitlement: mutateAsync,
    loading: isPending,
  };
};
