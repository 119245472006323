import React, { FC } from 'react';
import styles from './styles.module.scss';

interface Props {
  idLeft: string;
  checkedLeft: boolean;
  textLeft: string;
  idRight: string;
  checkedRight: boolean;
  textRight: string;
  name: string;
  onChange: (value: any) => void;
  valueLeft?: any;
  valueRight?: any;
}

export const RadioGroup: FC<Props> = ({
  idLeft,
  valueLeft,
  checkedLeft,
  textLeft,
  idRight,
  valueRight,
  checkedRight,
  textRight,
  name,
  onChange,
}) => {
  return (
    <div className={styles.radioGroup}>
      <div className={styles.inner}>
        <input
          id={idLeft}
          type="radio"
          name={name}
          value={valueLeft}
          onChange={onChange}
          checked={checkedLeft}
        />
        <label htmlFor={idLeft}>{textLeft}</label>
      </div>
      <div className={styles.inner}>
        <input
          id={idRight}
          type="radio"
          name={name}
          value={valueRight}
          onChange={onChange}
          checked={checkedRight}
        />
        <label htmlFor={idRight}>{textRight}</label>
      </div>
    </div>
  );
};
