import { ChangeEvent, FC } from 'react';
import { SelectOptionsProps } from './types';

interface Props {
  options: SelectOptionsProps[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: FC<Props> = ({ options, onChange }) => {
  return (
    <select onChange={onChange}>
      {options.map(({ value, title }) => (
        <option key={value} value={value}>
          {title}
        </option>
      ))}
    </select>
  );
};
