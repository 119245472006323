import { useContext } from 'react';
import { AppContext } from '../../../App';
import { LangTypes } from '../../types/common';
import cn from 'classnames';
import styles from './styles.module.scss';

export const LangSwitcher = () => {
  const { switchLang, lang } = useContext(AppContext);
  const currentLang: LangTypes = lang || 'en';

  const handleSwitch = (lang: LangTypes) => {
    switchLang(lang);
  };

  return (
    <div className={styles.wrapper}>
      <button
        className={cn({ [styles.active]: currentLang === 'en' })}
        onClick={() => handleSwitch('en')}
      >
        EN
      </button>
      <span>|</span>
      <button
        className={cn({ [styles.active]: currentLang === 'ru' })}
        onClick={() => handleSwitch('ru')}
      >
        RU
      </button>
    </div>
  );
};
