import { UploadImageRequest, UsersCountriesResponse } from './types';
import { ResponseCustom } from '../types';
import { API } from '../constants';

export const uploadImage = async ({
  formData,
  imageType,
  token,
}: UploadImageRequest): Promise<ResponseCustom<string>> => {
  const response = await fetch(`${API}/admin/upload-image?type=${imageType}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  return await response.json();
};

export const fetchUsersCountries = async (
  token: string
): Promise<ResponseCustom<UsersCountriesResponse[]>> => {
  const response = await fetch(`${API}/admin/users/countries`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
};
