import { adminRequest } from '../Admin/requests/login/requests';
import { isAdminValid } from '../Admin/common/auth';
import { redirect } from 'react-router-dom';

export const adminLoader = async () => {
  const token = localStorage.getItem('token');
  const response = await adminRequest(token);

  if (response?.data?.username) {
    const isAdmin = isAdminValid(response.data.username);
    if (!isAdmin) {
      return redirect('/admin-login');
    } else {
      return '';
    }
  }
  return redirect('/admin-login');
};
