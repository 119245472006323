import { useQuery } from '@tanstack/react-query';
import { fetchProductCategories } from './requests';
import { LONG_STALE_TIME, QUERY_KEYS } from '../constants';

type UseFetchProductCategories = () => {
  isFetched: boolean;
  categories?: string[];
};

export const useFetchProductCategories: UseFetchProductCategories = () => {
  const { data: response, isFetched } = useQuery({
    queryKey: [QUERY_KEYS.PRODUCT_CATEGORIES],
    queryFn: fetchProductCategories,
    refetchOnWindowFocus: false,
    staleTime: LONG_STALE_TIME,
  });

  return {
    isFetched,
    categories: response?.data?.categories,
  };
};
