import { useFetchProductsByIds } from '../../../requests/products/useFetchProductsByIds';
import {
  formatProduct,
  getLastOrderProduct,
  setDisplayProduct,
} from '../utils';
import { nanoid } from '../../../../common/utils/idGenerator';
import { ADD_PRODUCT_ID } from '../constants';
import { DEFAULT_PRODUCT_WEIGHT } from '../../../common/constants/products';
import { MenuSet } from '../../../common/types/menu';
import { Dispatch, SetStateAction } from 'react';

type AddProduct = ({
  event,
  dayId,
}: {
  event: any;
  dayId: string;
}) => Promise<void>;

type UseAddProduct = ({
  menuSet,
  setMenuSet,
}: {
  menuSet: MenuSet[];
  setMenuSet: Dispatch<SetStateAction<MenuSet[]>>;
}) => {
  addProduct: AddProduct;
  loading: boolean;
};

export const useAddProduct: UseAddProduct = ({ menuSet, setMenuSet }) => {
  const { fetchProductsByIds, loading } = useFetchProductsByIds();

  const addProduct: AddProduct = async ({ event, dayId }) => {
    const wrapper = event.target.closest(`#${ADD_PRODUCT_ID}`);
    const input = wrapper.getElementsByTagName('input')[0];

    const productIds = [];
    if (input) {
      productIds.push(input.value);
    }

    if (productIds.length) {
      const response = await fetchProductsByIds(productIds);

      if (response && response?.data?.length) {
        const lastOrder = getLastOrderProduct({ menuSet, dayId });
        const internalId = nanoid();
        const formattedProduct = formatProduct({
          internalId,
          order: lastOrder,
          product: response.data[0],
          weight: DEFAULT_PRODUCT_WEIGHT,
        });

        setDisplayProduct({
          dayId,
          product: formattedProduct,
          menuSet,
          setMenuSet,
        });

        input.value = null;
      }
    }
  };

  return {
    addProduct,
    loading,
  };
};
