import styles from './styles.module.scss';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useFetchUserByCustomerId } from '../../../../requests/users/useFetchUserByCustomerId';
import { InputButton } from '../../../../shared/InputButton';
import { SearchInput } from '../../../../components/SearchInput';
import {
  UserActivityLevel,
  UserAge,
  UserAva,
  UserCountry,
  UserCurrentWeight,
  UserCustomerId,
  UserEmail,
  UserFamilyName,
  UserFeedSystem,
  UserFirstInstallTime,
  UserGender,
  UserGoalTemp,
  UserHeight,
  UserLanguages,
  UserLastVisitedTime,
  UserName,
  UserOS,
  UserProfileName,
  UserTargetWeight,
  UserWaterLevel,
  UserWaterSystem,
} from '../../../../entities/UserProfile/components';
import { OwnProducts } from '../../../../entities/UserProfile/OwnProducts';
import { Promocode } from '../../../../entities/UserProfile/Promocode';
import { Entitlements } from '../../../../entities/UserProfile/Entitlements';
import { useFetchMenus } from '../../../../requests/menu/useFetchMenus';

export const MongoSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const { menusData } = useFetchMenus();

  const menuIds = useMemo(() => {
    return menusData?.map((item) => item.purchaseId);
  }, [menusData]);

  const { fetchUserByCustomerId, loading, user } = useFetchUserByCustomerId();

  const onSubmit = () => {
    fetchUserByCustomerId({ customerId: searchQuery });
  };

  const acquiredMenus = { raw: user?.entitlements || [] };

  return (
    <div className={styles.contentBlock}>
      <SearchInput loading={loading} onInputChange={onInputChange}>
        <InputButton text="Search" handler={onSubmit} disabled={!searchQuery} />
      </SearchInput>
      <div className={styles.main}>
        {user === null && <h2>No data</h2>}
        {!!user?.profile?.ava && (
          <UserAva src={user.profile.ava} alt={user.profile.name || ''} />
        )}
        {!!user?.customerId && <UserCustomerId customerId={user.customerId} />}
        {(!!user?.meta?.firstInstallTime || !!user?.firstInstallTime) && (
          <UserFirstInstallTime
            timestamp={
              user?.meta?.firstInstallTime || user.firstInstallTime || 0
            }
          />
        )}
        {!!user?.meta?.lastVisited &&
          (!!user?.meta?.firstInstallTime || !!user?.firstInstallTime) && (
            <UserLastVisitedTime
              lastTimestamp={user.meta.lastVisited}
              installTimestamp={
                user?.meta?.firstInstallTime || user.firstInstallTime || 0
              }
            />
          )}
        {!!user?.meta?.country && <UserCountry country={user.meta.country} />}
        {!!user?.meta?.languages && (
          <UserLanguages languages={user.meta.languages} />
        )}
        {!!user?.userPersonalData?.givenName && (
          <UserName name={user.userPersonalData.givenName} />
        )}
        {!!user?.userPersonalData?.familyName && (
          <UserFamilyName familyName={user.userPersonalData.familyName} />
        )}
        {!!user?.userPersonalData?.email && (
          <UserEmail email={user.userPersonalData.email} />
        )}
        {!!user?.userPersonalData?.OS && (
          <UserOS os={user.userPersonalData.OS} />
        )}
        {!!user?.profile?.name && (
          <UserProfileName profileName={user.profile.name} />
        )}
        {!!user?.profile?.gender && <UserGender gender={user.profile.gender} />}
        {!!user?.profile?.age && <UserAge age={user.profile.age} />}
        {!!user?.profile?.height && <UserHeight height={user.profile.height} />}
        {!!user?.profile?.weightCurrent && (
          <UserCurrentWeight weight={user.profile.weightCurrent} />
        )}
        {!!user?.profile?.weightTarget && (
          <UserTargetWeight weight={user.profile.weightTarget} />
        )}
        {!!user?.profile?.changeWeightTemp &&
          !!user?.profile?.weightCurrent &&
          !!user?.profile.weightTarget && (
            <UserGoalTemp
              temp={user.profile.changeWeightTemp}
              weightCurrent={user.profile.weightCurrent}
              weightTarget={user.profile.weightTarget}
            />
          )}
        {!!user?.profile?.activityLevel && (
          <UserActivityLevel activityLevel={user.profile.activityLevel} />
        )}
        {!!user?.profile?.feedSystem && (
          <UserFeedSystem feedSystem={user.profile.feedSystem} />
        )}
        {!!user?.profile?.waterSystem && (
          <UserWaterSystem waterSystem={user.profile.waterSystem} />
        )}
        {!!user?.profile?.waterLevel && (
          <UserWaterLevel waterLevel={user.profile.waterLevel} />
        )}
        {!!user?.ownProducts?.length && (
          <OwnProducts data={user?.ownProducts.map((item) => item.name)} />
        )}
        {!!user && (
          <Promocode
            documentId={user._id}
            endDate={user.promoCode?.endDate}
            refetch={onSubmit}
          />
        )}
        {!!user && (
          <Entitlements
            documentId={user._id}
            menuIds={menuIds}
            acquiredMenus={acquiredMenus}
            refetch={onSubmit}
          />
        )}
      </div>
    </div>
  );
};
