import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useFetchRecipesCategories } from '../../requests/recipes/useFetchRecipesCategories';
import { CategoriesList, CategoryProps } from '../CategoriesList';
import { RecipeCategoriesEnum } from '../../common/types/recipes';

interface RecipeCategoriesProps {
  activeCategory: RecipeCategoriesEnum;
  setActiveCategory: Dispatch<SetStateAction<RecipeCategoriesEnum>>;
}

export const RecipeCategories: FC<RecipeCategoriesProps> = ({
  activeCategory,
  setActiveCategory,
}) => {
  const { categories, isFetched } = useFetchRecipesCategories();

  const onCategoryClick = (category: RecipeCategoriesEnum) => {
    localStorage.setItem('currentRecipeCategory', category);
    setActiveCategory(category);
  };

  const categoriesList = useMemo<
    CategoryProps<RecipeCategoriesEnum>[] | undefined
  >(() => {
    return categories?.map((category) => ({ title: category }));
  }, [categories]);

  return (
    <CategoriesList
      clickHandler={onCategoryClick}
      activeCategory={activeCategory}
      isLoading={!isFetched}
      categories={categoriesList}
    />
  );
};
