import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './styles.module.scss';

export const HomeErates = () => {
  return (
    <section className="section">
      <div className={cn('container', styles.container)}>
        <Link className="link" to="/privacy-policy-erates">
          Privacy Policy
        </Link>
        <Link className="link" to="/subscription-agreement-erates">
          Subscription agreement
        </Link>
        <Link className="link" to="/contacts-erates">
          Contact Us
        </Link>
      </div>
    </section>
  );
};
