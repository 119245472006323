import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Link className="link" to="/privacy-policy">
        Privacy Policy
      </Link>
      <Link className="link" to="/contacts">
        Contact Us
      </Link>
    </footer>
  );
};
