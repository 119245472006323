import { FC, ReactElement } from 'react';
import { Button } from '../../../common/shared/Button';
import { Loader } from '../../../common/shared/Loader';
import styles from './styles.module.scss';
import { Modal } from '../../../common/shared/Modal';

interface Props {
  onCloseModal: () => void;
  confirmHandler: () => void;
  cancelHandler: () => void;
  customText?: string | ReactElement;
  loading?: boolean;
}

export const ConfirmModal: FC<Props> = ({
  onCloseModal,
  confirmHandler,
  cancelHandler,
  customText,
  loading = false,
}) => {
  return (
    <Modal onClose={onCloseModal}>
      <div className={styles.confirmModal}>
        <Loader visible={loading} />
        <p className={styles.text}>
          {!!customText && <>{customText}</>}
          {!customText && (
            <>
              Точно? <span className={styles.emoji}>🤨</span>
            </>
          )}
        </p>
        <div className={styles.buttonsWrapper}>
          <Button handler={confirmHandler}>
            <span>ДА</span>
          </Button>
          <Button handler={cancelHandler} outlined>
            <span>НЕТ</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
