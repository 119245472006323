import '../../common/styles/index.scss';
import { Header } from '../Header';
import { Outlet } from 'react-router-dom';
import styles from './styles.module.scss';

export const Layout = () => {
  return (
    <>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
    </>
  );
};
