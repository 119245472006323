import { MenuSet } from '../../../common/types/menu';
import { Dispatch, SetStateAction } from 'react';

type DeleteProductFunc = ({
  dayId,
  internalId,
}: {
  dayId: string;
  internalId: string;
}) => void;

type UseDeleteProduct = ({
  menuSet,
  setMenuSet,
  deleteDay,
}: {
  menuSet: MenuSet[];
  setMenuSet: Dispatch<SetStateAction<MenuSet[]>>;
  deleteDay: (dayId: string) => void;
}) => DeleteProductFunc;

export const useDeleteProduct: UseDeleteProduct = ({
  menuSet,
  setMenuSet,
  deleteDay,
}) => {
  return ({ dayId, internalId }) => {
    const targetDay = menuSet.find((item) => item.day?.dayId === dayId);

    const filteredProducts = targetDay?.products?.filter(
      (product) => product.internalId !== internalId
    );
    const filteredMenu = menuSet.filter((item) => item.day?.dayId !== dayId);

    if (targetDay && filteredProducts?.length) {
      targetDay.products = filteredProducts;
      const sortedMenuSet = [...filteredMenu, targetDay].sort(
        (a, b) => (a.day?.value || 0) - (b.day?.value || 0)
      );
      setMenuSet(sortedMenuSet);
    }

    if (targetDay && !filteredProducts?.length) {
      deleteDay(dayId);
    }
  };
};
