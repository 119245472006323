import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from '../../../common/styles/header.module.scss';

export const HeaderErates = () => {
  return (
    <header className={styles.header}>
      <div className={cn(styles.container, 'container')}>
        <Link className={styles.logoLink} to="/erates">
          <img className={styles.logo} src="/assets/images/eRates-logo.png" alt="eRates" />
          <p className={styles.text}>
            eRates<span className="desktopShow">: Exchange rates</span>
          </p>
        </Link>
      </div>
    </header>
  );
};
