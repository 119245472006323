import styles from './styles.module.scss';
import { FC } from 'react';

interface Props {
  data: string[];
}

export const OwnProducts: FC<Props> = ({ data }) => {
  return (
    <dl className={styles.row}>
      <dt>Собственные продукты</dt>
      <dd>
        {data.map((item, index) => (
          <div
            key={index}
            className={styles.badge}
            dangerouslySetInnerHTML={{
              __html: item,
            }}
          />
        ))}
      </dd>
    </dl>
  );
};
