import { FC, useState } from 'react';
import { TabDataProps } from './types';
import { TabButton } from './ui/TabButton';
import styles from './styles.module.scss';

interface Props {
  data: TabDataProps[];
}

export const Tabs: FC<Props> = ({ data }) => {
  const [activeTab, setActiveTab] = useState<string>(data[0].id);

  const handleActiveButton = (id: string) => {
    setActiveTab(id);
  };

  const tabsMap: Record<string, TabDataProps> = data.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item,
    };
  }, {});

  return (
    <div className={styles.tabsWrapper}>
      <div className={styles.tabsRow}>
        {data.map(({ id, label }) => (
          <TabButton
            key={id}
            onClick={() => handleActiveButton(id)}
            label={label}
            isActive={activeTab === id}
          />
        ))}
      </div>
      {tabsMap[activeTab].children}
    </div>
  );
};
