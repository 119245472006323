import { FC } from 'react';
import { Spinner } from '../Spinner';
import styles from './styles.module.scss';

interface Props {
  visible: boolean;
}

export const Loader: FC<Props> = ({ visible }) => {
  return (
    <>
      {visible && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
    </>
  );
};
