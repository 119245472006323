import { useCalcRecipeNutrients } from '../../../common/hooks/useCalcRecipeNutrients';
import { getLastOrderProduct, setDisplayProduct } from '../utils';
import { nanoid } from '../../../../common/utils/idGenerator';
import { MenuSet } from '../../../common/types/menu';
import { Dispatch, SetStateAction } from 'react';
import { ADD_RECIPE_ID } from '../constants';
import { DEFAULT_PRODUCT_WEIGHT } from '../../../common/constants/products';

type AddRecipe = ({
  event,
  dayId,
}: {
  event: any;
  dayId: string;
}) => Promise<void>;

type UseAddRecipe = ({
  menuSet,
  setMenuSet,
}: {
  menuSet: MenuSet[];
  setMenuSet: Dispatch<SetStateAction<MenuSet[]>>;
}) => {
  addRecipe: AddRecipe;
  loading: boolean;
};

export const useAddRecipe: UseAddRecipe = ({ menuSet, setMenuSet }) => {
  const { calcRecipeNutrients, loading } = useCalcRecipeNutrients();

  const addRecipe: AddRecipe = async ({ event, dayId }) => {
    const wrapper = event.target.closest(`#${ADD_RECIPE_ID}`);
    const input = wrapper.getElementsByTagName('input')[0];

    let recipeId = '';
    if (input) {
      recipeId = input.value;
    }

    if (recipeId) {
      const lastOrder = getLastOrderProduct({ menuSet, dayId });
      const internalId = nanoid();
      const formattedRecipe = await calcRecipeNutrients({
        internalId,
        order: lastOrder,
        recipeId,
        weight: DEFAULT_PRODUCT_WEIGHT,
      });

      if (formattedRecipe) {
        setDisplayProduct({
          dayId,
          product: formattedRecipe,
          menuSet,
          setMenuSet,
        });

        input.value = null;
      }
    }
  };

  return {
    addRecipe,
    loading,
  };
};
