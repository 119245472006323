export enum ImageType {
  recipe = 'recipe',
  menu = 'menu',
  messageBannerBackground = 'messageBannerBackground',
  messageBannerImage = 'messageBannerImage',
}

export interface UploadImageRequest {
  formData: FormData;
  imageType: ImageType;
  token: string;
}

export interface ReplaceImageNameRequest {
  itemId: string;
  name: string;
}

export interface UsersCountriesResponse {
  country: string;
  count: number;
}
