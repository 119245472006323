import { API, API_KEY } from '../constants';
import { ResponseCustom } from '../types';
import { UserSearchProps } from '../../common/types/users';
import { SearchRequest, SearchResponse } from '../../common/types/search';

export const searchUsers = async ({
  query,
  page,
}: SearchRequest): Promise<ResponseCustom<SearchResponse<UserSearchProps>>> => {
  const response = await fetch(
    `${API}/users-search?query=${query}&page=${page}&size=50`,
    {
      method: 'GET',
      headers: {
        Authorization: `${API_KEY}`,
      },
    }
  );
  return await response.json();
};
