import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { getUserProducts } from './requests';
import { ResponseCustom } from '../types';
import { ProductsPropsWithDbId } from '../../common/types/products';
import { LONG_STALE_TIME, QUERY_KEYS } from '../constants';

type UseFetchUserProducts = () => {
  isLoading: boolean;
  refetch: () => Promise<
    QueryObserverResult<ResponseCustom<ProductsPropsWithDbId[]>>
  >;
  data?: ProductsPropsWithDbId[];
};

export const useFetchUserProducts: UseFetchUserProducts = () => {
  const token = localStorage.getItem('token') || '';

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.USER_PRODUCTS, token],
    queryFn: () => getUserProducts(token),
    refetchOnWindowFocus: false,
    staleTime: LONG_STALE_TIME,
  });

  return {
    isLoading,
    refetch,
    data: response?.data,
  };
};
