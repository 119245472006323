import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { ResponseCustom } from '../types';
import { UserProps } from '../../common/types/users';
import { PickedTokensRequest } from './types';
import { fetchPickedTokens } from './requests';

type UseFetchPickedTokens = () => {
  fetchPickedTokens: UseMutateAsyncFunction<
    ResponseCustom<UserProps[]>,
    unknown,
    PickedTokensRequest
  >;
  loading: boolean;
  users?: UserProps[];
};

export const useFetchPickedTokens: UseFetchPickedTokens = () => {
  const token = localStorage.getItem('token') || '';

  const {
    mutateAsync,
    isPending,
    data: response,
  } = useMutation<ResponseCustom<UserProps[]>, unknown, PickedTokensRequest>({
    mutationFn: ({ customerIds }) => fetchPickedTokens({ token, customerIds }),
  });

  return {
    fetchPickedTokens: mutateAsync,
    loading: isPending,
    users: response?.data,
  };
};
