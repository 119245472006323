import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { patchPromocode } from './requests';
import { PatchPromocodeRequest } from './types';
import { ResponseCustom } from '../types';

type UsePatchPromocode = () => {
  patchPromocode: UseMutateAsyncFunction<
    ResponseCustom<any>,
    unknown,
    PatchPromocodeRequest
  >;
  loading: boolean;
};

export const usePatchPromocode: UsePatchPromocode = () => {
  const { mutateAsync, isPending } = useMutation<
    ResponseCustom<any>,
    unknown,
    PatchPromocodeRequest
  >({
    mutationFn: ({ documentId, promoCode }) =>
      patchPromocode({ documentId, promoCode }),
  });

  return {
    patchPromocode: mutateAsync,
    loading: isPending,
  };
};
